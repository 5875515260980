import { StyleSheet, css } from "aphrodite/no-important";
import * as color from "../../../constants/color";
import * as texchangePropTypes from "../../../texchangePropTypes";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ErrorMessage from "../../text/ErrorMessage";
import BodyCopyHighlight from "../../text/BodyCopyHighlight";
import DropdownRow from "./DropdownRow";
import { ReactComponent as ChevronDown } from "../../../images/SVGs/chevron-down.svg";
import { ReactComponent as ChevronUp } from "../../../images/SVGs/chevron-up.svg";
import Tooltip from "../../Tooltip";
import TooltipWarning from "../../TooltipWarning";

const VERTICAL_PADDING_DIFFERENCE = 0;
const ADDITIONAL_PADDING = 20;
const ITEM_HEIGHT = 37;
const VERTICAL_PADDING = 200;

const styles = StyleSheet.create({
    container: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    inputContainer: {
        flex: 1,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "stretch",
        outline: 0,
    },
    disabled: {
        cursor: "not-allowed",
    },
    labelContainer: {
        cursor: "pointer",
        flexGrow: 1,
        flexShrink: 0,
        flexBasis: "auto",
        position: "relative",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: 15,
        paddingTop: 26,
        border: "none",
        backgroundColor: color.transparent,
        textAlign: "left",
        outline: 0,
        width: "100%",
        minWidth: "100%",
        ":focus": {
            border: "solid",
            borderColor: color.lightOrange,
            borderWidth: 2,
            borderRadius: 4,
            paddingBottom: 10,
        },
    },
    labelContainerSmall: {
        padding: 16,
        paddingTop: 14,
        paddingBottom: 12,
    },
    noLabel: {
        paddingTop: 22,
    },
    noLabelSmall: {
        paddingTop: 12,
    },
    text: {
        fontSize: 14,
        height: 19,
        flexGrow: 1,
        flexShrink: 0,
        flexBasis: "auto",
        fontWeight: 400,
        marginRight: 6,
        color: color.darkestGray,
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "calc(100% - 18px)",
        whiteSpace: "nowrap",
    },
    placeholder: {
        color: color.darkGray,
    },
    disabledText: {
        color: color.darkestBlueAlpha40,
    },
    title: {
        position: "absolute",
        top: 6,
        left: 11,
        fontSize: 13,
        color: color.darkGray,
        whiteSpace: "nowrap",
    },
    option: {
        flexGrow: 1,
        flexShrink: 0,
        flexBasis: "auto",
        whiteSpace: "nowrap",
    },
    borderContainer: {
        transition: "height 0.3s",
        border: `1px solid ${color.mediumGray}`,
        backgroundColor: color.white,
        borderRadius: 4,
        position: "absolute",
        top: 0,
        right: 0,
        left: 0,
        overflow: "hidden",
        width: "100%",
        minWidth: "100%",
    },
    borderContainerOpen: {
        transition: "height 0.3s",
        border: `1px solid ${color.lightGray2}`,
        backgroundColor: color.white,
        borderRadius: 4,
        position: "absolute",
        top: 49,
        left: 0,
        minWidth: "100%",
    },
    optionsContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "stretch",
    },
    extendUp: {
        bottom: 0,
        top: "initial",
    },
    chevron: {
        pointerEvents: "none",
        tint: color.mediumYellow1,
    },
    invisible: {
        visibility: "hidden",
    },
    validationMessage: {
        position: "absolute",
        top: 6,
        right: 8,
        pointerEvents: "none",
    },
    blue: {
        backgroundColor: color.inputFieldGray,
    }
});

const strings = {
    chevronUp: "Close select dropdown",
    chevronDown: "Open select dropdown",
    avatar: "Profile avatar",
};

// TODO: make chevron grey when disabled if that state is ever used
export default class Dropdown extends Component {
    static propTypes = {
        aStyles: texchangePropTypes.aphroditeStyles,
        placeholder: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
        options: PropTypes.array.isRequired,
        name: PropTypes.string,
        disabled: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
        extendUp: PropTypes.bool,
        hideLabel: PropTypes.bool,
        validationMessage: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.string,
        ]),
        size: PropTypes.string,
        allowEmpty: PropTypes.bool,
        showAsBlue: PropTypes.bool,
        tooltip: PropTypes.string,
        warning: PropTypes.string,
        tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    };

    static defaultProps = {
        options: [],
    };

    constructor(props) {
        super(props);
        this._inputRef = React.createRef();
        this._dropdownRef = React.createRef();
        this.state = {
            open: false,
            dropdownHeight: null,
            inputHeight: null,
            optionsFiltered: [],
            selectedIndex: 0,
            searchTerm: '',
        };
    }

    componentDidMount() {
        this._mounted = true;
        setTimeout(() => {
            if (!this._mounted) {
                return;
            }

            this.setState({
                optionsFiltered: this.props.options,
                selectedIndex: this.props.options.findIndex(o => o.value === this.props.value),
                dropdownHeight: this._getDropdownHeight(this._inputRef.current.clientHeight),
                inputHeight: this._inputRef.current.clientHeight ? this._inputRef.current.clientHeight : this.props.size === "small" ? 48 : 58,
            });
        });
        window.addEventListener("click", this._onClick);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.options.length !== this.props.options.length || this.state.dropdownHeight === (VERTICAL_PADDING_DIFFERENCE + ADDITIONAL_PADDING)) {
            setTimeout(() => {
                if (!this._mounted) {
                    return;
                }

                this.setState({
                    optionsFiltered: this.props.options,
                    selectedIndex: this.props.options.findIndex(o => o.value === this.props.value),
                    dropdownHeight: this._getDropdownHeight(this.state.inputHeight),
                    searchTerm: '',
                });
            });
        }
    }

    componentWillUnmount() {
        this._mounted = false;
        window.removeEventListener("click", this._onClick);
    }

    _getDropdownHeight = inputHeight => {
        if (!this._dropdownRef.current) {
            return null;
        }

        const dd = this._dropdownRef.current.getBoundingClientRect();
        const appHeight = document.body.classList.contains("no-scroll")
            ? window.innerHeight
            : document.body.clientHeight;

        const { allowEmpty, value } = this.props;
        let { length } = this.state.optionsFiltered; //this.props.options;

        if (allowEmpty && value && length > 0) {
            length++;
        }

        let popupHeight
        if (length > 8) {
            popupHeight = (8 * ITEM_HEIGHT) - VERTICAL_PADDING_DIFFERENCE;
        } else {
            popupHeight = length * ITEM_HEIGHT;
        }

        if (this.props.extendUp) {
            return popupHeight;
        }

        const yOffset = window.pageYOffset || document.documentElement.scrollTop;

        let height;
        if (dd.bottom + yOffset + VERTICAL_PADDING > appHeight) {
            height = appHeight - yOffset - dd.top - VERTICAL_PADDING - ADDITIONAL_PADDING;
        } else {
            height = popupHeight + VERTICAL_PADDING_DIFFERENCE;
        }

        //min height...  1 result
        return height > 37 ? height : 37;
    };

    _openDropdown = () => {
        this.setState({
            optionsFiltered: this.props.options,
            dropdownHeight: this._getDropdownHeight(this.state.inputHeight),
            searchTerm: '',
            open: true,
        });
    };

    _closeDropdown = () => this.setState({ open: false });

    _onClick = e => {
        if (e && this._inputRef.current.contains(e.target)) {
            return;
        }
        this._closeDropdown();
    };

    _onSelect = (value, index) => {
        if (this.props.name) {
            this.props.onChange({
                value: value,
                index: index,
                name: this.props.name,
            });
        } else {
            this.props.onChange(value, index);
        }

        this._closeDropdown();
    };

    _renderOptions = () => {
        const { allowEmpty, value, placeholder } = this.props;
        //const options = Object.assign([], this.props.options);
        const options = Object.assign([], this.state.optionsFiltered);

        if (allowEmpty && value && options.length > 0) {
            options.unshift({
                label: placeholder,
                value: "",
            });
        }

        return (
            <div
                ref={this._dropdownRef}
                className={css(styles.optionsContainer)}
                style={
                    this.state.open
                        ? {
                            height: this.state.dropdownHeight,
                            overflowY: "auto",
                            paddingBottom: this.props.extendUp ? 0 : VERTICAL_PADDING_DIFFERENCE,
                            paddingTop: this.props.extendUp ? VERTICAL_PADDING_DIFFERENCE : 0,
                        }
                        : null
                }
            >
                {options.map(this._renderOption)}
            </div>
        );
    };

    _renderOption = (option, index) => (
        <DropdownRow
            key={index}
            index={index}
            onSelect={this._onSelect}
            label={option.label}
            value={option.value}
            extraLabel={option.extraLabel}
            disabled={option.disabled}
            aStyles={[styles.option, !this.state.open ? styles.invisible : null]}
        />
    );

    _getValueLabel = () => {
        for (const option of this.props.options) {
            if (option.value === this.props.value) {
                return option.label;
            }
        }

        return this.props.placeholder;
    };

    _onKeyDown = e => {
        var pressed = e.key;
        if (pressed === "Enter") {
            this._closeDropdown();
        } else if (pressed === "ArrowUp" || pressed === "ArrowDown") {
            e.preventDefault();
            let index = this.state.selectedIndex;
            if (pressed === "ArrowUp" && this.state.selectedIndex > 0) {
                index = index - 1;
            } else if (pressed === "ArrowDown" && this.state.optionsFiltered.length > (index + 1)) {
                index = index + 1;
            }
            //change
            this.props.onChange(this.state.optionsFiltered[index].value, 0);
            this.setState({ selectedIndex: index });
        } else {
            var textInput = this.state.searchTerm + pressed.replace(/[^0-9a-zA-Z]+/g, "");
            if (textInput && textInput.length >= 1) {
                let filtered = this.state.optionsFiltered.filter(o => o.label.toLowerCase().includes(textInput) && !o.label.toLowerCase().startsWith("all "));
                if (filtered && filtered.length === 1) {
                    this.props.onChange(filtered[0].value, 0);
                    this._closeDropdown();
                }
                else if (filtered && filtered.length > 1) {
                    //keep the "all" option
                    const first = this.props.options[0];
                    if (first.label.toLowerCase().startsWith("all ")) {
                        filtered.unshift(first);
                    }
                    //update the filtered list
                    this.setState({
                        optionsFiltered: filtered,
                        dropdownHeight: this._getDropdownHeight(this.state.inputHeight),
                        searchTerm: textInput,
                    });
                }
            }
        }
    };

    render() {
        const isSmall = this.props.size === "small";
        return (
            <div className={css(styles.container)}>
                <div
                    ref={this._inputRef}
                    className={css(
                        styles.inputContainer,
                        this.props.aStyles,
                    )}
                    style={{
                        zIndex: this.state.open ? 10000 : null
                    }}
                    tabIndex={this.props.tabIndex}
                    onKeyDown={this._onKeyDown}
                >
                    <div
                        className={css(
                            this.state.open ? styles.borderContainerOpen : styles.borderContainer,
                            this.props.showAsBlue ? styles.blue : null,
                            this.props.extendUp ? styles.extendUp : null,
                        )}
                        style={{
                            marginTop: this.state.open && !isSmall
                                ? 10
                                : null,
                            height: this.state.open
                                ? this.state.dropdownHeight
                                : this.state.inputHeight,
                        }}
                    >
                        {this.props.validationMessage ? (
                            <ErrorMessage aStyles={styles.validationMessage}>
                                {this.props.validationMessage}
                            </ErrorMessage>
                        ) : null}
                        {this._renderOptions()}
                        {this.props.value !== "" && this.props.value !== null && !this.props.hideLabel && !this.state.open ? (
                            <div className={css(styles.title)}>{this.props.placeholder}</div>
                        ) : null}
                    </div>
                    <button
                        type="button"
                        disabled={this.props.disabled}
                        className={css(
                            styles.labelContainer,
                            isSmall ? styles.labelContainerSmall : null,
                            this.props.disabled ? styles.disabled : null,
                            this.props.hideLabel ? (isSmall ? styles.labelContainerSmall : styles.noLabel) : null
                        )}
                        onClick={this.state.open ? this._closeDropdown : this._openDropdown}
                    >
                        <BodyCopyHighlight
                            aStyles={[styles.text, this.props.value === null || this.props.value === "" ? styles.placeholder : null, this.props.disabled ? styles.disabledText : null]}
                        >
                            {this.props.value !== null || this.props.value !== "" ? this._getValueLabel() : this.props.placeholder}
                        </BodyCopyHighlight>
                        {(this.state.open && !this.props.extendUp) ||
                            (!this.state.open && this.props.extendUp) ? (
                            <ChevronUp alt={strings.chevronUp} className={css(styles.chevron)} />
                        ) : (
                            <ChevronDown alt={strings.chevronDown} className={css(styles.chevron)} />
                        )}
                    </button>
                </div>
                {this.props.tooltip ? <Tooltip text={this.props.tooltip} /> : null}
                {this.props.warning ? <TooltipWarning text={this.props.warning} /> : null}
            </div>
        );
    }
}
