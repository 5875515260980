import React, { Component } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
//import { ReactComponent as DownloadIcon } from "../../images/SVGs/Download.svg";
import Modal from "../Modal";
import TextInput from "../inputs/TextInput";
import Tabs from "../Tabs";
import ActionButton from "../ActionButton";
import Dropdown from "../inputs/Dropdown";
import Checkbox from "../inputs/Checkbox";
import Toggle from "../inputs/Toggle";
import carriers from "../../constants/carriers";
import * as util from "../../lib/util";
import * as texchangePropTypes from "../../texchangePropTypes";
import * as color from "../../constants/color";
import * as inputSizes from "../../constants/inputSizes";
import * as quoteProposalApi from "../../lib/api/quoteProposal";
import coverPage1 from "../../images/cover_page_t1.png";
import coverPage2 from "../../images/cover_page_t2.png";
import coverPage3 from "../../images/cover_page_t3.png";
import coverPage4 from "../../images/cover_page_t4.png";
import { ToastContainer, toast } from "react-toastify";

const styles = StyleSheet.create({
    container: {
        paddingTop: 40,
        paddingLeft: 24,
        paddingRight: 24,
        color: color.darkestGray,
    },
    headerActionsContainer: {
        display: "flex",
        justifyContent: "space-between",
    },
    clearContainer: {

    },
    planMessageContainer: {
        paddingTop: 0,
    },
    planMessageWarning: {
        color: color.red,
    },
    proposalContainer: {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: 20,
    },
    proposalDropdown: {
        marginRight: 24,
        maxWidth: 240,
        minWidth: 180,
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: -10,
    },
    baseRateDropdown: {
        marginRight: 50,
        maxWidth: 340,
        minWidth: 280,
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: -10,
    },
    saveProposalButton: {
        width: 180,
    },
    outputContainer: {
        display: "flex",
        fontSize: 16,
    },
    outputOptionContainer: {
        width: "35%",
        minWidth: 625,
        border: `1px solid ${color.mediumGray}`,
        marginRight: 42,
        alignSelf: "baseline",
    },
    outputsContainer: {
        flexGrow: 1,
    },
    outputOptionItem: {
        marginTop: 32,
        marginBottom: 32,
        marginLeft: 28,
        marginRight: 28,
    },
    largeCheckLabel: {
        display: "block",
        position: "relative",
        border: "none",
        "-webkit-appearance": "none",
        cursor: "pointer",
    },
    largeCheck: {
        position: "absolute",
        top: -9,
        left: 0,
        marginRight: 30,
    },
    largeLabelText: {
        marginLeft: 48,
        fontSize: 18,
        color: color.darkestGray,
        whiteSpace: "nowrap",
    },
    outputOptionHeader: {
        backgroundColor: color.mediumRed,
        color: color.white,
        fontSize: 20,
        fontWeight: 500,
        padding: "10px 28px",
    },
    outputItem: {
        display: "flex",
        border: `1px solid ${color.darkGray4}`,
        marginBottom: 10,
    },
    optionItemLabel: {
        display: "flex",
        alignItems: "center",
        borderRight: `1px solid ${color.darkGray4}`,
        backgroundColor: color.lightGray2, //needs to be changed
        fontSize: 16,
        fontWeight: 500,
        width: "30%",
        minWidth: 260,
        padding: "20px 24px",
    },
    optionItemContent: {
        padding: "20px 32px",
        width: "100%",
    },
    coverPageLabel: {
        fontWeight: 500,
    },
    coverPageRow: {
        display: "flex",
        paddingTop: 2,
        paddingBottom: 2,
    },
    coverPageColumn: {
        flex: 1,
    },
    addAnotherOutputContainer: {
        border: `1px dashed ${color.darkGray4}`,
        paddingTop: 36,
        paddingBottom: 36,
        textAlign: "center",
        fontSize: 20,
        fontWeight: 300,
        color: color.darkestGray,
    },
    chooseOptionsContainer: {
        border: `1px dashed ${color.darkGray4}`,
        textAlign: "center",
        fontSize: 20,
        paddingTop: 80,
        height: "100%"
    },
    optionField: {
        display: "inline-block",
        marginRight: 20,
    },
    optionHeader: {
        borderBottom: `2px solid ${color.mediumRed}`,
        paddingBottom: 4,
        marginBottom: 10,
    },
    spacer: {
        marginTop: 26,
    },
    checkboxContainer: {
        marginTop: 12,
    },
    checkboxContainerLeft: {
        marginTop: 12,
        float: "left",
    },
    checkLabel: {
        display: "block",
        position: "relative",
        width: "50%",
        border: "none",
        marginBottom: 12,
        whiteSpace: "nowrap",
        "-webkit-appearance": "none",
        cursor: "pointer",
        ":last-child": {
            marginBottom: 0,
        }
    },
    check: {
        position: "absolute",
        top: -2,
        left: 0,
        marginRight: 10,
    },
    labelText: {
        marginLeft: 30,
        fontSize: 16,
    },
    labelInfo: {
        marginLeft: 30,
        fontSize: 16,
        fontStyle: "italic",
    },
    downloadIcon: {
        float: "right",
    },
    carrierContainer: {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
        maxHeight: 100,
    },
    carrierItem: {
        marginTop: 6,
        marginBottom: 6,
        flex: 1,
    },
    modalContent: {
        width: 380,
    },
    modalInstructions: {
        color: color.darkestGray,
        fontSize: 16,
        marginTop: 16,
        marginBottom: 16,
    },
    modalButtonContainer: {
        marginTop: 24,
        paddingTop: 24,
        display: "flex",
        justifyContent: "space-between",
        clear: "both",
    },
    modalButton: {
        width: 150,
    },
    fileTypeContainer: {
        marginTop: 24,
        fontSize: 16,
        color: color.darkestGray,
    },
    fileType: {
        float: "left",
    },
    radioLabel: {
        display: "block",
        marginBottom: 12,
    },
    radioContainer: {
        float: "right",
    },
    radio: {
        marginLeft: 12,
        float: "right",
    },
    setBasePlan: {
        float: "right",
        marginTop: 4,
    },
    smallText: {
        color: color.darkGray4,
        fontSize: 13,
        fontStyle: "italic",
        paddingLeft: 5,
        margin: "5px 0",
    },
    radioCover: {
        margin: "42px 8px",
        verticalAlign: "top",
    },
});

const strings = {
    loadProposalPlaceholder: "Load Proposal",
    saveProposaButtonText: "Save proposal",
    proposalNamePlaceholder: "Proposal name",
    outputOptionsHeader: "Output Options",
    addAnotherOutputText: "Add another output option…",
    chooseOptionsText: "Choose Output options from the menu on the left.",
    saveProposalModalHeader: "Save Proposal",
    saveButtonText: "Save",
    cancelButtonText: "Cancel",
    clearButtonText: "Clear",
    proposalInstructions: "Name your proposal below:",
    proposalEmailLabel: "Email this proposal to the broker:",
    required: "Please fill out this field",
    baseMedicalPlan: "Base Medical Plan",
    selectBasePlan: "Select",
};

const inputNames = {
    name: "name",
    selectedProposalId: "selectedProposalId",
};

const outputInputNames = {
    fileType: "fileType",
    coverPage: {
        included: "included",
    },
    coverPageImage: "coverPageImage",
    companyProfile: {
        included: "included",
    },
    census: {
        included: "included",
    },
    censusRates: {
        included: "included",
        sortByCarrier: "sortByCarrier",
        ageRated: "ageRated",
        compositeRated: "compositeRated",
    },
    currentAndRenewal: {
        included: "included",
        ageRated: "ageRated",
        ageRatedWithCensus: "ageRatedWithCensus",
        compositeRated: "compositeRated",
    },
    costComparisons: {
        included: "included",
        sortByCarrierAndPlanType: "sortByCarrierAndPlanType",
        currentAndRenewalPlans: "currentAndRenewalPlans",
        compositeRated: "compositeRated",
        ageRated: "ageRated",
    },
    costComparisonsD: {
        included: "included",
        currentAndRenewalPlans: "currentAndRenewalPlans",
    },
    costComparisonsV: {
        included: "included",
        currentAndRenewalPlans: "currentAndRenewalPlans",
    },
    costComparisonsL: {
        included: "included",
        //currentAndRenewalPlans: "currentAndRenewalPlans",
    },
    spreadsheets: {
        included: "included",
        singlePlan: "singlePlan",
        multiPlan: "multiPlan",
        stackedMultiPlan: "stackedMultiPlan",
    },
    dynamicSpreadsheets: {
        included: "included",
        currentAndRenewalPlans: "currentAndRenewalPlans",
        singlePlan: "singlePlan",
        singlePlanAge: "singlePlanAge",
        multiPlan: "multiPlan",
        dualOption: "dualOption",
        tripleOption: "tripleOption",
        quadrupleOption: "quadrupleOption",
    },
    employeeWorksheet: {
        included: "included",
        ageRated: "ageRated",
        compositeRated: "compositeRated",
    },
    employeeWorksheetD: {
        included: "included",
    },
    employeeWorksheetV: {
        included: "included",
    },
    tableRates: {
        included: "included",
        sortByCarrier: "sortByCarrier",
        ageRates: "ageRates",
        ageRatesVertical: "ageRatesVertical",
        compositeRates: "compositeRates",
    },
    tableRatesD: {
        included: "included",
    },
    tableRatesV: {
        included: "included",
    },
    underwritingGuidelines: {
        included: "included",
        carriers: "carriers",
    },
};

const optionOutputInputItemsMedical = [
    { label: "Cover Page", value: "coverPage" },
    { label: "Company Profile", value: "companyProfile" },
    { label: "Census", value: "census" },
    { label: "Census Rates", value: "censusRates" },
    { label: "Current & Renewal", value: "currentAndRenewal" },
    { label: "Cost Comparisons", value: "costComparisons" },
    { label: "Saved Spreadsheets", value: "spreadsheets" },
    { label: "Side-by-Side Dynamic Spreadsheets", value: "dynamicSpreadsheets" },
    { label: "Employee Worksheet ACA", value: "employeeWorksheet" },
    { label: "Table Rates", value: "tableRates" },
    //{ label: "Underwriting Guidelines", value: "underwritingGuidelines" },
];

const optionOutputInputItemsDental = [
    { label: "Cost Comparisons", value: "costComparisonsD" },
    { label: "Employee Worksheet", value: "employeeWorksheetD" },
    { label: "Table Rates", value: "tableRatesD" },
];

const optionOutputInputItemsVision = [
    { label: "Cost Comparisons", value: "costComparisonsV" },
    { label: "Employee Worksheet", value: "employeeWorksheetV" },
    { label: "Table Rates", value: "tableRatesV" },
];

const optionOutputInputItemsLife = [
    { label: "Cost Comparisons", value: "costComparisonsL" },
];

export class Output extends Component {
    static propTypes = {
        quote: texchangePropTypes.quote.isRequired,
        spreadsheets: PropTypes.object.isRequired,
        selectedPlans: PropTypes.object.isRequired,
        me: texchangePropTypes.me.isRequired,
    };

    constructor(props) {
        super(props);

        const outputOptionInputs = {};
        const effective = new Date(this.props.quote.effectiveDate);
        const oneMonthAgo = new Date(new Date().setMonth(new Date().getMonth() - 1));

        optionOutputInputItemsMedical.forEach(item => {
            outputOptionInputs[item.value] = false;
        });
        //defaults
        outputOptionInputs.coverPage = true;
        outputOptionInputs.companyProfile = true;
        outputOptionInputs.census = true;

        optionOutputInputItemsDental.forEach(item => {
            outputOptionInputs[item.value] = false;
        });

        optionOutputInputItemsVision.forEach(item => {
            outputOptionInputs[item.value] = false;
        });

        optionOutputInputItemsLife.forEach(item => {
            outputOptionInputs[item.value] = false;
        });

        this.state = {
            inputs: {
                [inputNames.selectedProposalId]: "",
                [inputNames.name]: "",
            },
            outputs: this._getDefaultOutputs(),
            outputOptionInputs,
            showSaveModal: false,
            errors: [],
            isSaving: false,
            quoteProposals: [],
            allowNewProposals: effective > oneMonthAgo,
        };

        this._getQuoteProposals();
    }

    _getDefaultOutputs = () => {
        return {
            [outputInputNames.fileType]: "xlsx",
            [outputInputNames.coverPageImage]: "1",
            coverPage: {
                [outputInputNames.coverPage.included]: true,
            },
            companyProfile: {
                [outputInputNames.companyProfile.included]: true,
            },
            census: {
                [outputInputNames.census.included]: true,
            },
            censusRates: {
                [outputInputNames.censusRates.included]: false,
                [outputInputNames.censusRates.sortByCarrier]: true,
                [outputInputNames.censusRates.ageRated]: false,
                [outputInputNames.censusRates.compositeRated]: false,
            },
            currentAndRenewal: {
                [outputInputNames.currentAndRenewal.included]: false,
                [outputInputNames.currentAndRenewal.ageRated]: false,
                [outputInputNames.currentAndRenewal.ageRatedWithCensus]: false,
                [outputInputNames.currentAndRenewal.compositeRated]: false,
            },
            costComparisons: {
                [outputInputNames.costComparisons.included]: false,
                [outputInputNames.costComparisons.sortByCarrierAndPlanType]: true,
                [outputInputNames.costComparisons.currentAndRenewalPlans]: false,
                [outputInputNames.costComparisons.compositeRated]: false,
                [outputInputNames.costComparisons.ageRated]: false,
            },
            costComparisonsD: {
                [outputInputNames.costComparisonsD.included]: false,
                [outputInputNames.costComparisonsD.currentAndRenewalPlans]: false,
            },
            costComparisonsV: {
                [outputInputNames.costComparisonsV.included]: false,
                [outputInputNames.costComparisonsV.currentAndRenewalPlans]: false,
            },
            costComparisonsL: {
                [outputInputNames.costComparisonsV.included]: false,
                //[outputInputNames.costComparisonsV.currentAndRenewalPlans]: false,
            },
            spreadsheets: {
                [outputInputNames.spreadsheets.included]: false,
                [outputInputNames.spreadsheets.singlePlan]: [],
                [outputInputNames.spreadsheets.multiPlan]: [],
                [outputInputNames.spreadsheets.stackedMultiPlan]: [],
            },
            dynamicSpreadsheets: {
                [outputInputNames.dynamicSpreadsheets.included]: false,
                [outputInputNames.dynamicSpreadsheets.currentAndRenewalPlans]: this.props.quote.currentMedicalPlans ? true : false,
                [outputInputNames.dynamicSpreadsheets.singlePlan]: false,
                [outputInputNames.dynamicSpreadsheets.singlePlanAge]: false,
                [outputInputNames.dynamicSpreadsheets.multiPlan]: false,
                [outputInputNames.dynamicSpreadsheets.dualOption]: false,
                [outputInputNames.dynamicSpreadsheets.tripleOption]: false,
                [outputInputNames.dynamicSpreadsheets.quadrupleOption]: false,
            },
            employeeWorksheet: {
                [outputInputNames.employeeWorksheet.included]: false,
                [outputInputNames.employeeWorksheet.ageRated]: false,
                [outputInputNames.employeeWorksheet.compositeRated]: false,
            },
            employeeWorksheetD: {
                [outputInputNames.employeeWorksheetD.included]: false,
            },
            employeeWorksheetV: {
                [outputInputNames.employeeWorksheetV.included]: false,
            },
            tableRates: {
                [outputInputNames.tableRates.included]: false,
                [outputInputNames.tableRates.sortByCarrier]: true,
                [outputInputNames.tableRates.ageRates]: false,
                [outputInputNames.tableRates.ageRatesVertical]: false,
                [outputInputNames.tableRates.compositeRates]: false,
            },
            tableRatesD: {
                [outputInputNames.tableRatesD.included]: false,
            },
            tableRatesV: {
                [outputInputNames.tableRatesV.included]: false,
            },
            /*underwritingGuidelines: {
                [outputInputNames.underwritingGuidelines.included]: false,
                [outputInputNames.underwritingGuidelines.carriers]: [],
            },*/
        };
    };

    _getQuoteProposals = () => {
        quoteProposalApi.getQuoteProposalsByQuoteId(this.props.quote.id)
            .then(result => {
                this.setState({
                    quoteProposals: result,
                });
            });
    };

    _quoteProposalSelected = value => {
        const quoteProposal = this.state.quoteProposals.find(p => p.id === parseInt(value));
        const output = JSON.parse(quoteProposal.outputs);
        const outputResults = JSON.parse(quoteProposal.outputResults);

        //cover page image was added 05/2024
        if (!outputResults.coverImage) {
            output.outputs.coverPageImage = "1";
        } else {
            output.outputs.coverPageImage = outputResults.coverImage;
        }

        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.selectedProposalId]: value,
                    [inputNames.name]: quoteProposal.name,
                },
                outputOptionInputs: output.outputOptionInputs,
                outputs: output.outputs,
            };
        });
    };

    _quoteProposalBaseMedicalPlanSelected = value => {
        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.selectedBaseMedicalPlanId]: value,
                },
            };
        });
    };

    _validForm = () => {
        const errors = {};
        const { inputs } = this.state;

        Object.keys(inputs).forEach(stateKey => {
            if (stateKey === inputNames.selectedProposalId) {
                return;
            }

            if (inputs[stateKey] === null || inputs[stateKey] === "") {
                errors[stateKey] = strings.required;
            }
        });

        this.setState({ errors });

        return !Object.keys(errors).length;
    };


    _saveProposal = () => {
        const outputResult = this._getOutputResult();
        if (!outputResult || !outputResult["quotePages"] || outputResult["quotePages"].length <= 3) {
            toast.error("At least one output must be selected.", { toastId: 10 });
            return;
        }

        const { inputs, outputs, outputOptionInputs, allowNewProposals } = this.state;
        const { quote, selectedPlans, me } = this.props;
        const { currentMedicalPlans, renewalMedicalPlans } = quote;

        //date check...  if effective more than 1 month ago then don't allow
        if (!me.isSysAdmin && !allowNewProposals) {
            toast.error("You cannot create new proposals for past effective dates.", { toastId: 11 });
            return;
        }

        //Get plan ids
        let medicalPlanIds = selectedPlans.medical.map(p => p.id);
        let dentalPlanIds = selectedPlans.dental.map(p => p.id);
        let visionPlanIds = selectedPlans.vision.map(p => p.id);
        let lifePlanIds = selectedPlans.life.map(p => p.id);

        //Load plans from the previous proposal?
        let selectedProposalId = parseInt(inputs[inputNames.selectedProposalId]);
        if (selectedProposalId > 0 && this.state.quoteProposals) {
            const selectedProposal = this.state.quoteProposals.find(p => p.id === selectedProposalId);
            //don't mess with parent quote's proposals...  save a copy
            if (selectedProposal.quoteId !== quote.id) {
                selectedProposalId = 0;
            }
            medicalPlanIds = selectedProposal.medicalPlanIds;
            dentalPlanIds = selectedProposal.dentalPlanIds;
            visionPlanIds = selectedProposal.visionPlanIds;
            lifePlanIds = selectedProposal.lifePlanIds;
        }

        //plans selected?
        if ((!medicalPlanIds || medicalPlanIds.length === 0) && (!dentalPlanIds || dentalPlanIds.length === 0) && (!visionPlanIds || visionPlanIds.length === 0) && (!lifePlanIds || lifePlanIds.length === 0) && (!currentMedicalPlans || currentMedicalPlans.length === 0) && (!renewalMedicalPlans || renewalMedicalPlans.length === 0)) {
            toast.error("There are no plans selected.", { toastId: 12 });
            return;
        }

        //passed
        if (this.state.showSaveModal) {
            //valid?
            if (!this._validForm()) {
                return;
            }

            //passed
            this.setState({ isSaving: true });

            const name = inputs[inputNames.name].replace(/\s/g, '');
            const group = quote.groupName.replace(/\s/g, '');
            let ext = outputs[outputInputNames.fileType];
            //If employee worksheets are included, then we return a zip
            if (outputResult["quotePages"].indexOf("EmployeeWorksheet-Age") > -1 || outputResult["quotePages"].indexOf("EmployeeWorksheet-Composite") > -1 || outputResult["quotePages"].indexOf("EmployeeWorksheet-Dental") > -1 || outputResult["quotePages"].indexOf("EmployeeWorksheet-Vision") > -1) {
                ext = "zip";
            }

            const quoteProposal = {
                id: selectedProposalId ? selectedProposalId : 0,
                name: inputs[inputNames.name],
                quoteId: quote.id,
                brokerId: quote.brokerId,
                outputs: JSON.stringify({
                    outputOptionInputs: outputOptionInputs,
                    outputs: outputs,
                }),
                medicalPlanIds: medicalPlanIds,
                dentalPlanIds: dentalPlanIds,
                visionPlanIds: visionPlanIds,
                lifePlanIds: lifePlanIds,
                baseMedicalPlanId: inputs[inputNames.selectedBaseMedicalPlanId] ? inputs[inputNames.selectedBaseMedicalPlanId] : 0,
                //baseDentalPlanId: inputs[inputNames.selectedBaseDentalPlanId] ? inputs[inputNames.selectedBaseDentalPlanId] : 0,
                //baseVisionPlanId: inputs[inputNames.selectedBaseVisionPlanId] ? inputs[inputNames.selectedBaseVisionPlanId] : 0,
                //baseLifePlanId: inputs[inputNames.selectedBaseLifePlanId] ? inputs[inputNames.selectedBaseLifePlanId] : 0,
                outputResults: JSON.stringify(outputResult),
                fileName: group + "_" + name + "_" + quote.id + "." + ext,
                employeeContribution: quote.employeeContribution ? quote.employeeContribution : null,
                dependentContribution: quote.dependentContribution ? quote.dependentContribution : null
            };

            //submit to output generator
            quoteProposalApi.saveQuoteProposal(quoteProposal)
                .then(response => {
                    if (response && response.startsWith("ERROR")) {
                        console.log("Output generation error: " + response);
                        if (response.indexOf("code 413") > -1) {
                            toast.error("There was an error encountered while generating your output.  Try reducing the number of 'Selected Plans' that you have saved with this proposal.", { toastId: 13 });
                        } else {
                            toast.error("There was an error encountered while generating your output.", { toastId: 13 });
                        }
                    }
                    this.setState({ isSaving: false });
                    this._getQuoteProposals();
                    this._clearInputs();
                });
        } else {
            this.setState({ showSaveModal: true });
        }
    };

    _clearInputs = () => {
        const outputOptionInputs = {};

        optionOutputInputItemsMedical.forEach(item => {
            outputOptionInputs[item.value] = false;
        });
        //defaults
        outputOptionInputs.coverPage = true;
        outputOptionInputs.companyProfile = true;
        outputOptionInputs.census = true;

        optionOutputInputItemsDental.forEach(item => {
            outputOptionInputs[item.value] = false;
        });

        optionOutputInputItemsVision.forEach(item => {
            outputOptionInputs[item.value] = false;
        });

        optionOutputInputItemsLife.forEach(item => {
            outputOptionInputs[item.value] = false;
        });

        this.setState({
            inputs: {
                [inputNames.selectedProposalId]: "",
                [inputNames.name]: "",
            },
            outputOptionInputs,
            outputs: this._getDefaultOutputs(),
            showSaveModal: false,
        });
    }

    _getOutputResult = () => {
        const { outputs } = this.state;
        let selected = false;
        let errors = false;

        const result = {};

        const quotePages = [];

        //include these three on ALL outputs
        quotePages.push("CoverPage");
        quotePages.push("CompanyProfile");
        quotePages.push("Census");

        if (outputs.censusRates[outputInputNames.censusRates.included]) {
            const sortByCarrier = outputs.censusRates[outputInputNames.censusRates.sortByCarrier] ? "-Carrier" : "";
            //did user choose something?
            selected = false;
            if (outputs.censusRates[outputInputNames.censusRates.ageRated]) {
                selected = true;
                quotePages.push("CensusRates-Age" + sortByCarrier);
            }
            if (outputs.censusRates[outputInputNames.censusRates.compositeRated]) {
                selected = true;
                quotePages.push("CensusRates-Composite" + sortByCarrier);
            }
            //did user choose something?
            if (!selected) {
                toast.error("No Census Rates outputs were added.  Please select one or more of the available options.", { toastId: 1 });
                errors = true;
            }
        }

        if (outputs.currentAndRenewal[outputInputNames.currentAndRenewal.included]) {
            //did user choose something?
            selected = false;
            if (outputs.currentAndRenewal[outputInputNames.currentAndRenewal.ageRated]) {
                selected = true;
                quotePages.push("CurrentAndRenewal-Age");
            }
            if (outputs.currentAndRenewal[outputInputNames.currentAndRenewal.ageRatedWithCensus]) {
                selected = true;
                quotePages.push("CurrentAndRenewal-Age-WithCensus");
            }
            if (outputs.currentAndRenewal[outputInputNames.currentAndRenewal.compositeRated]) {
                selected = true;
                quotePages.push("CurrentAndRenewal-Composite");
            }
            //did user choose something?
            if (!selected) {
                toast.error("No Current & Renewal outputs were added.  Please select one or more of the available options.", { toastId: 2 });
                errors = true;
            }
        }

        if (outputs.costComparisons[outputInputNames.costComparisons.included]) {
            const sortByCarrierAndPlanType = outputs.costComparisons[outputInputNames.costComparisons.sortByCarrierAndPlanType] ? "Carrier" : "Premium";
            const withCurrent = outputs.costComparisons[outputInputNames.costComparisons.currentAndRenewalPlans] ? "WithCurrentAndRenewal" : "WithoutCurrentAndRenewal";
            //did user choose something?
            selected = false;
            if (outputs.costComparisons[outputInputNames.costComparisons.compositeRated]) {
                selected = true;
                quotePages.push(`CC-${sortByCarrierAndPlanType}-Composite-${withCurrent}`);
            }
            if (outputs.costComparisons[outputInputNames.costComparisons.ageRated]) {
                selected = true;
                quotePages.push(`CC-${sortByCarrierAndPlanType}-Age-${withCurrent}`);
            }
            //did user choose something?
            if (!selected) {
                toast.error("No Cost Comparison outputs were added.  Please select one or more of the available options.", { toastId: 3 });
                errors = true;
            }
        }

        if (outputs.costComparisonsD[outputInputNames.costComparisonsD.included]) {
            const withCurrent = outputs.costComparisonsD[outputInputNames.costComparisonsD.currentAndRenewalPlans] ? "WithCurrentAndRenewal" : "WithoutCurrentAndRenewal";
            quotePages.push(`CC-Premium-Dental-${withCurrent}`);
        }

        if (outputs.costComparisonsV[outputInputNames.costComparisonsV.included]) {
            const withCurrent = outputs.costComparisonsV[outputInputNames.costComparisonsV.currentAndRenewalPlans] ? "WithCurrentAndRenewal" : "WithoutCurrentAndRenewal";
            quotePages.push(`CC-Premium-Vision-${withCurrent}`);
        }

        if (outputs.costComparisonsL[outputInputNames.costComparisonsL.included]) {
            //const withCurrent = outputs.costComparisonsV[outputInputNames.costComparisonsV.currentAndRenewalPlans] ? "WithCurrentAndRenewal" : "WithoutCurrentAndRenewal";
            const withCurrent = "WithoutCurrentAndRenewal";
            quotePages.push(`CC-Premium-Life-${withCurrent}`);
        }

        if (outputs.spreadsheets[outputInputNames.spreadsheets.included]) {
            //did user choose something?
            selected = false;
            if (outputs.spreadsheets[outputInputNames.spreadsheets.singlePlan].length > 0) {
                for (const spreadsheet of outputs.spreadsheets[outputInputNames.spreadsheets.singlePlan]) {
                    const singlePlanSpreadsheet = this.props.spreadsheets.singlePlan.find(s => s.name === spreadsheet);
                    if (singlePlanSpreadsheet) {
                        const spreadsheetName = "SBS-SingleOption-" + (singlePlanSpreadsheet.rateType === "Age" ? "Age" : "Composite");
                        if (!quotePages.includes(spreadsheetName)) {
                            selected = true;
                            quotePages.push(spreadsheetName);
                        }
                    }
                }
            }
            if (outputs.spreadsheets[outputInputNames.spreadsheets.multiPlan].length > 0) {
                for (const spreadsheet of outputs.spreadsheets[outputInputNames.spreadsheets.multiPlan]) {
                    const multiPlanSpreadsheet = this.props.spreadsheets.multiPlan.find(s => s.name === spreadsheet);
                    if (multiPlanSpreadsheet) {
                        const spreadsheetName = "SBS-MultiOption-" + (multiPlanSpreadsheet.rateType === "Age" ? "Age" : "Composite");
                        if (!quotePages.includes(spreadsheetName)) {
                            selected = true;
                            quotePages.push(spreadsheetName);
                        }
                    }
                }
            }
            if (outputs.spreadsheets[outputInputNames.spreadsheets.stackedMultiPlan].length > 1) {
                toast.error("Our output process can only accommodate 1 Stacked Multi-Option spreadsheet on a single Proposal.  Please select only one Stacked Multi-Option spreadsheet at a time.", { toastId: 4 });
                errors = true;
            } else if (outputs.spreadsheets[outputInputNames.spreadsheets.stackedMultiPlan].length > 0) {
                for (const spreadsheet of outputs.spreadsheets[outputInputNames.spreadsheets.stackedMultiPlan]) {
                    const stackedMultiPlanSpreadsheet = this.props.spreadsheets.stackedMultiPlan.find(s => s.name === spreadsheet);
                    if (stackedMultiPlanSpreadsheet) {
                        const spreadsheetName = "SBS-StackedMultiOption-" + (stackedMultiPlanSpreadsheet.rateType === "Age" ? "Age" : "Composite");
                        if (!quotePages.includes(spreadsheetName)) {
                            selected = true;
                            quotePages.push(spreadsheetName);
                        }
                    }
                }
            }
            //did user choose something?
            if (!selected) {
                toast.error("No Saved Spreadsheet outputs were added.  Please select one or more of the available options.", { toastId: 5 });
                errors = true;
            }
        }

        if (outputs.dynamicSpreadsheets[outputInputNames.dynamicSpreadsheets.included]) {
            //did user choose something?
            const withCurrent = outputs.dynamicSpreadsheets[outputInputNames.dynamicSpreadsheets.currentAndRenewalPlans] ? "-WithCurrent" : "";
            selected = false;
            if (outputs.dynamicSpreadsheets[outputInputNames.dynamicSpreadsheets.singlePlan]) {
                selected = true;
                quotePages.push("SBS-CustomSingleOption-Composite");
            }
            if (outputs.dynamicSpreadsheets[outputInputNames.dynamicSpreadsheets.singlePlanAge]) {
                selected = true;
                quotePages.push("SBS-CustomSingleOption-Age");
            }
            if (outputs.dynamicSpreadsheets[outputInputNames.dynamicSpreadsheets.multiPlan]) {
                selected = true;
                quotePages.push("SBS-CustomMultiOption-Composite");
            }
            if (outputs.dynamicSpreadsheets[outputInputNames.dynamicSpreadsheets.dualOption]) {
                selected = true;
                quotePages.push(`SBS-CustomDualOption-Composite${withCurrent}`);
            }
            if (outputs.dynamicSpreadsheets[outputInputNames.dynamicSpreadsheets.tripleOption]) {
                selected = true;
                quotePages.push(`SBS-CustomTripleOption-Composite${withCurrent}`);
            }
            if (outputs.dynamicSpreadsheets[outputInputNames.dynamicSpreadsheets.quadrupleOption]) {
                selected = true;
                quotePages.push(`SBS-CustomQuadrupleOption-Composite${withCurrent}`);
            }
            //did user choose something?
            if (!selected) {
                toast.error("No Dynamic Spreadsheet outputs were added.  Please select one or more of the available options.", { toastId: 6 });
                errors = true;
            }
        }

        if (outputs.employeeWorksheet[outputInputNames.employeeWorksheet.included]) {
            //did user choose something?
            selected = false;
            const baseId = this.state.inputs[inputNames.selectedBaseMedicalPlanId];
            if (baseId && outputs.employeeWorksheet[outputInputNames.employeeWorksheet.ageRated]) {
                selected = true;
                quotePages.push("EmployeeWorksheet-Age");
            }
            if (baseId && outputs.employeeWorksheet[outputInputNames.employeeWorksheet.compositeRated]) {
                selected = true;
                quotePages.push("EmployeeWorksheet-Composite");
            }
            //did user choose something?
            if (!selected) {
                toast.error("No Employee Worksheet outputs were added.  Please select one or more of the available options.", { toastId: 7 });
                errors = true;
            }
        }

        if (outputs.employeeWorksheetD[outputInputNames.employeeWorksheetD.included]) {
            quotePages.push("EmployeeWorksheet-Dental");
        }

        if (outputs.employeeWorksheetV[outputInputNames.employeeWorksheetV.included]) {
            quotePages.push("EmployeeWorksheet-Vision");
        }

        if (outputs.tableRates[outputInputNames.tableRates.included]) {
            const sortByCarrier = outputs.tableRates[outputInputNames.tableRates.sortByCarrier] ? "-Carrier" : "";
            //did user choose something?
            selected = false;
            if (outputs.tableRates[outputInputNames.tableRates.ageRates]) {
                selected = true;
                quotePages.push("TableRates-FullAge" + sortByCarrier);
            }
            if (outputs.tableRates[outputInputNames.tableRates.ageRatesVertical]) {
                selected = true;
                quotePages.push("TableRates-FullAge-Vertical" + sortByCarrier);
            }
            if (outputs.tableRates[outputInputNames.tableRates.compositeRates]) {
                selected = true;
                quotePages.push("TableRates-Composite" + sortByCarrier);
            }
            //did user choose something?
            if (!selected) {
                toast.error("No Table Rate outputs were added.  Please select one or more of the available options.", { toastId: 8 });
                errors = true;
            }
        }

        if (outputs.tableRatesD[outputInputNames.tableRatesD.included]) {
            quotePages.push("TableRates-Dental");
        }

        if (outputs.tableRatesV[outputInputNames.tableRatesV.included]) {
            quotePages.push("TableRates-Vision");
        }

        /*if (outputs.underwritingGuidelines[outputInputNames.underwritingGuidelines.included]) {                        
            if (outputs.underwritingGuidelines[outputInputNames.underwritingGuidelines.carriers].length > 0) {
                quotePages.push("UnderwritingGuidelines");    
            }
        }*/

        //stop here if there were one or more errors returned
        if (errors) {
            return;
        }

        result["quotePages"] = quotePages;
        result["quoteFormat"] = outputs[outputInputNames.fileType];
        result["coverImage"] = outputs[outputInputNames.coverPageImage];

        return result;
    }

    _handleTextChange = e => {
        const { name, value } = e.target;

        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: value,
                },
            };
        });
    };

    _outputOptionCheckChanged = e => {
        const { checked, name, type, value } = e.target;
        const val = type === "checkbox" ? checked : value;

        const { quote, selectedPlans } = this.props;
        const { currentMedicalPlans, renewalMedicalPlans, newMedicalPlans, currentDentalPlans, renewalDentalPlans, currentVisionPlans, renewalVisionPlans, currentLifePlans, renewalLifePlans } = quote;
        const currentBasePlan = currentMedicalPlans.find(p => p.isBase === true);
        const renewalBasePlan = renewalMedicalPlans.find(p => p.isBase === true);

        if (name === "currentAndRenewal") {
            //must have current or renewal chosen
            if (currentMedicalPlans.length === 0 && renewalMedicalPlans.length === 0) {
                toast.warn("At least one current or renewal plan must be selected for this output.", { toastId: 3 });
                return;
            }
        }

        if (name === "currentAndRenewalD") {
            //must have current or renewal chosen
            if (currentDentalPlans.length === 0 && renewalDentalPlans.length === 0) {
                toast.warn("At least one current or renewal plan must be selected for this output.", { toastId: 3 });
                return;
            }
        }

        if (name === "currentAndRenewalV") {
            //must have current or renewal chosen
            if (currentVisionPlans.length === 0 && renewalVisionPlans.length === 0) {
                toast.warn("At least one current or renewal plan must be selected for this output.", { toastId: 3 });
                return;
            }
        }

        /*if (name === "currentAndRenewalL") {
            //must have current or renewal chosen
            if (currentLifePlans.length === 0 && renewalLifePlans.length === 0) {
                toast.warn("At least one current or renewal plan must be selected for this output.", { toastId: 3 });
                return;
            }
        }*/

        if (name === "currentAndRenewal" || name === "employeeWorksheet") {
            //must have base plans set
            if (currentMedicalPlans.length > 0 && !currentBasePlan) {
                toast.warn("You must choose a current base plan for this output.", { toastId: 4 });
                return;
            }
            if (renewalMedicalPlans.length > 0 && !renewalBasePlan) {
                toast.warn("You must choose a renewal base plan for this output.", { toastId: 5 });
                return;
            }
        }

        if (name === "costComparisons" || name === "employeeWorksheet" || name === "censusRates" || name === "tableRates" || name === "dynamicSpreadsheets") {
            //must have new plans selected
            if (newMedicalPlans.length === 0 && selectedPlans.medical.length === 0) {
                toast.warn("At least one new plan must be selected for this output.", { toastId: 6 });
                return;
            }
        }

        if (name === "costComparisonsD" || name === "employeeWorksheetD" || name === "tableRatesD") {
            //must have new plans selected
            if (selectedPlans.dental.length === 0) {
                toast.warn("At least one new plan must be selected for this output.", { toastId: 6 });
                return;
            }
        }

        if (name === "costComparisonsV" || name === "employeeWorksheetV" || name === "tableRatesV") {
            //must have new plans selected
            if (selectedPlans.vision.length === 0) {
                toast.warn("At least one new plan must be selected for this output.", { toastId: 6 });
                return;
            }
        }

        if (name === "costComparisonsL") {
            //must have new plans selected
            if (selectedPlans.life.length === 0) {
                toast.warn("At least one new plan must be selected for this output.", { toastId: 6 });
                return;
            }
        }

        if (name === "employeeWorksheet" && (selectedPlans.medical.length > 20)) {
            //can only have 20 plans max
            toast.warn("You can have a maximum of 20 New Plans (per line of coverage) selected for Employee Worksheets.", { toastId: 7 });
            return;
        }

        if (name === "employeeWorksheetD" && (selectedPlans.dental.length > 20)) {
            //can only have 20 plans max
            toast.warn("You can have a maximum of 20 New Plans (per line of coverage) selected for Employee Worksheets.", { toastId: 7 });
            return;
        }

        if (name === "employeeWorksheetV" && (selectedPlans.vision.length > 20)) {
            //can only have 20 plans max
            toast.warn("You can have a maximum of 20 New Plans (per line of coverage) selected for Employee Worksheets.", { toastId: 7 });
            return;
        }

        this.setState(prevState => {
            return {
                outputOptionInputs: {
                    ...prevState.outputOptionInputs,
                    [name]: val,
                },
                outputs: {
                    ...prevState.outputs,
                    [name]: {
                        ...prevState.outputs[name],
                        included: val,
                    },
                },
            };
        });
    };

    _censusRateSortChanged = value => {
        this.setState(prevState => {
            return {
                outputs: {
                    ...prevState.outputs,
                    censusRates: {
                        ...prevState.outputs.censusRates,
                        [outputInputNames.censusRates.sortByCarrier]: value,
                    },
                },
            };
        });
    };

    _censusRateCheckChanged = e => {
        const { checked, name, type, value } = e.target;
        const val = type === "checkbox" ? checked : value;

        // -- Begin - valid rates check --------------------------------------------------
        //check to make sure that you have plans that have age or composite rates
        if (val) {
            const { selectedPlans } = this.props;
            if (name === "ageRated") {
                //check to make sure that some of your New plans have age rates
                const newRates = selectedPlans.medical.filter(p => p.ageMonthlyPremium !== "$0.00" && p.ageMonthlyPremium !== null).length;
                if (newRates === 0) {
                    toast.warn("None of the New Plans that you selected have Age Rates.  This output would not be generated.", { toastId: 1 });
                    return;
                }
            } else if (name === "compositeRated") {
                //check to make sure that some of your New plans have age rates
                const newRates = selectedPlans.medical.filter(p => p.monthlyPremium !== "$0.00" && p.monthlyPremium !== null).length;
                if (newRates === 0) {
                    toast.warn("None of the New Plans that you selected have Composite Rates.  This output would not be generated.", { toastId: 1 });
                    return;
                }
            }
        }
        // -- End - valid rates check ----------------------------------------------------

        this.setState(prevState => {
            return {
                outputs: {
                    ...prevState.outputs,
                    censusRates: {
                        ...prevState.outputs.censusRates,
                        [name]: val,
                    },
                },
            };
        });
    };

    _currentAndRenewalCheckChanged = e => {
        const { checked, name, type, value } = e.target;
        const val = type === "checkbox" ? checked : value;
        //check
        const { census } = this.props.quote;
        const currentAgeRates = census.filter(c => c.currentMedicalAgeRate).length > 0;
        const currentCmpRates = census.filter(c => c.currentMedicalCompositeRate).length > 0;
        if ((name === "ageRated" || name === "ageRatedWithCensus") && !currentAgeRates) {
            toast.warn("You must load Current & Renewal Age Rates for this output.", { toastId: 1 });
            return;
        } else if (name === "compositeRated" && !currentCmpRates) {
            toast.warn("You must load Current & Renewal Composite Rates for this output.", { toastId: 1 });
            return;
        } else {
            this.setState(prevState => {
                return {
                    outputs: {
                        ...prevState.outputs,
                        currentAndRenewal: {
                            ...prevState.outputs.currentAndRenewal,
                            [name]: val,
                        },
                    },
                };
            });
        }
    };

    _costComparisonSortChanged = value => {
        this.setState(prevState => {
            return {
                outputs: {
                    ...prevState.outputs,
                    costComparisons: {
                        ...prevState.outputs.costComparisons,
                        [outputInputNames.costComparisons.sortByCarrierAndPlanType]: value,
                    },
                },
            };
        });
    };

    _costComparisonsCheckChanged = (e, lob) => {
        const { checked, name, type, value } = e.target;
        const val = type === "checkbox" ? checked : value;

        // -- Begin - valid rates check --------------------------------------------------
        //check to make sure that you have plans that have age or composite rates
        if (val) {
            const { selectedPlans } = this.props;
            if (name === "ageRated") {
                //check to make sure that some of your New plans have age rates
                const newRates = selectedPlans.medical.filter(p => p.ageMonthlyPremium !== "$0.00" && p.ageMonthlyPremium !== null).length;
                if (newRates === 0) {
                    toast.warn("None of the New Plans that you selected have Age Rates.  This output would not be generated.", { toastId: 1 });
                    return;
                }
            } else if (name === "compositeRated") {
                //check to make sure that some of your New plans have age rates
                const newRates = selectedPlans.medical.filter(p => p.monthlyPremium !== "$0.00" && p.monthlyPremium !== null).length;
                if (newRates === 0) {
                    toast.warn("None of the New Plans that you selected have Composite Rates.  This output would not be generated.", { toastId: 1 });
                    return;
                }
            }
        }
        // -- End - valid rates check ----------------------------------------------------

        if (lob === "medical") {
            if (name === "currentAndRenewalPlans" && val) {
                const { currentMedicalPlans, renewalMedicalPlans } = this.props.quote;

                const currentBasePlan = currentMedicalPlans.find(p => p.isBase === true);
                const renewalBasePlan = renewalMedicalPlans.find(p => p.isBase === true);

                //must have current or renewal chosen
                if (currentMedicalPlans.length === 0 && renewalMedicalPlans.length === 0) {
                    toast.warn("At least one current or renewal plan must be selected.", { toastId: 4 });
                    return;
                }
                //must have base plans set
                if (currentMedicalPlans.length > 0 && !currentBasePlan) {
                    toast.warn("You must choose a current base plan for this output.", { toastId: 5 });
                    return;
                }
                if (renewalMedicalPlans.length > 0 && !renewalBasePlan) {
                    toast.warn("You must choose a renewal base plan for this output.", { toastId: 6 });
                    return;
                }
            }

            this.setState(prevState => {
                return {
                    outputs: {
                        ...prevState.outputs,
                        costComparisons: {
                            ...prevState.outputs.costComparisons,
                            [name]: val,
                        },
                    },
                };
            });
        } else if (lob === "dental") {
            if (name === "currentAndRenewalPlans" && val) {
                const { currentDentalPlans, renewalDentalPlans } = this.props.quote;

                //must have current or renewal chosen
                if (currentDentalPlans.length === 0 && renewalDentalPlans.length === 0) {
                    toast.warn("At least one current or renewal plan must be selected.", { toastId: 4 });
                    return;
                }
            }

            this.setState(prevState => {
                return {
                    outputs: {
                        ...prevState.outputs,
                        costComparisonsD: {
                            ...prevState.outputs.costComparisonsD,
                            [name]: val,
                        },
                    },
                };
            });
        } else if (lob === "vision") {
            if (name === "currentAndRenewalPlans" && val) {
                const { currentVisionPlans, renewalVisionPlans } = this.props.quote;

                //must have current or renewal chosen
                if (currentVisionPlans.length === 0 && renewalVisionPlans.length === 0) {
                    toast.warn("At least one current or renewal plan must be selected.", { toastId: 4 });
                    return;
                }
            }

            this.setState(prevState => {
                return {
                    outputs: {
                        ...prevState.outputs,
                        costComparisonsV: {
                            ...prevState.outputs.costComparisonsV,
                            [name]: val,
                        },
                    },
                };
            });
        } else if (lob === "life") {
            /*if (name === "currentAndRenewalPlans" && val) {
                const { currentLifePlans, renewalLifePlans} = this.props.quote;

                //must have current or renewal chosen
                if (currentLifePlans.length === 0 && renewalLifePlans.length === 0) {
                    toast.warn("At least one current or renewal plan must be selected.", { toastId: 4 });
                    return;
                }
            }*/

            this.setState(prevState => {
                return {
                    outputs: {
                        ...prevState.outputs,
                        costComparisonsL: {
                            ...prevState.outputs.costComparisonsL,
                            [name]: val,
                        },
                    },
                };
            });
        }
    };

    _employeeWorksheetCheckChanged = e => {
        const { checked, name, type, value } = e.target;
        const val = type === "checkbox" ? checked : value;

        // -- Begin - valid rates check --------------------------------------------------
        //check to make sure that you have plans that have age or composite rates
        if (val) {
            const { selectedPlans } = this.props;
            if (name === "ageRated") {
                //check to make sure that some of your New plans have age rates
                const newRates = selectedPlans.medical.filter(p => p.ageMonthlyPremium !== "$0.00" && p.ageMonthlyPremium !== null).length;
                if (newRates === 0) {
                    toast.warn("None of the New Plans that you selected have Age Rates.  This output would not be generated.", { toastId: 1 });
                    return;
                }
            } else if (name === "compositeRated") {
                //check to make sure that some of your New plans have age rates
                const newRates = selectedPlans.medical.filter(p => p.monthlyPremium !== "$0.00" && p.monthlyPremium !== null).length;
                if (newRates === 0) {
                    toast.warn("None of the New Plans that you selected have Composite Rates.  This output would not be generated.", { toastId: 1 });
                    return;
                }
            }
        }
        // -- End - valid rates check ----------------------------------------------------

        this.setState(prevState => {
            return {
                outputs: {
                    ...prevState.outputs,
                    employeeWorksheet: {
                        ...prevState.outputs.employeeWorksheet,
                        [name]: val,
                    },
                },
            };
        });
    };

    _tableRateSortChanged = value => {
        this.setState(prevState => {
            return {
                outputs: {
                    ...prevState.outputs,
                    tableRates: {
                        ...prevState.outputs.tableRates,
                        [outputInputNames.tableRates.sortByCarrier]: value,
                    },
                },
            };
        });
    };

    _tableRateCheckChanged = e => {
        const { checked, name, type, value } = e.target;
        const val = type === "checkbox" ? checked : value;

        // -- Begin - valid rates check --------------------------------------------------
        //check to make sure that you have plans that have age or composite rates
        if (val) {
            const { selectedPlans } = this.props;
            if (name === "ageRates" || name === "ageRatesVertical") {
                //check to make sure that some of your New plans have age rates
                const newRates = selectedPlans.medical.filter(p => p.ageMonthlyPremium !== "$0.00" && p.ageMonthlyPremium !== null).length;
                if (newRates === 0) {
                    toast.warn("None of the New Plans that you selected have Age Rates.  This output would not be generated.", { toastId: 1 });
                    return;
                }
            } else if (name === "compositeRates") {
                //check to make sure that some of your New plans have age rates
                const newRates = selectedPlans.medical.filter(p => p.monthlyPremium !== "$0.00" && p.monthlyPremium !== null).length;
                if (newRates === 0) {
                    toast.warn("None of the New Plans that you selected have Composite Rates.  This output would not be generated.", { toastId: 1 });
                    return;
                }
            }
        }
        // -- End - valid rates check ----------------------------------------------------

        this.setState(prevState => {
            return {
                outputs: {
                    ...prevState.outputs,
                    tableRates: {
                        ...prevState.outputs.tableRates,
                        [name]: val,
                    },
                },
            };
        });
    };

    _dynamicSpreadsheetsCRChanged = value => {
        const { currentMedicalPlans, renewalMedicalPlans } = this.props.quote;

        if (value && !currentMedicalPlans && !renewalMedicalPlans) {
            toast.warn("There are no Current or Renewal plans selected.", { toastId: 1 });
        }

        this.setState(prevState => {
            return {
                outputs: {
                    ...prevState.outputs,
                    dynamicSpreadsheets: {
                        ...prevState.outputs.dynamicSpreadsheets,
                        [outputInputNames.dynamicSpreadsheets.currentAndRenewalPlans]: value,
                    },
                },
            };
        });
    };

    _underwritingGuidelinesCheckChanged = e => {
        const { checked, type } = e.target;
        const value = parseInt(e.target.value);
        const val = type === "checkbox" ? checked : value;

        const selectedCarriers = this.state.outputs.underwritingGuidelines.carriers;

        if (val) {
            selectedCarriers.push(value);
        } else {
            const index = selectedCarriers.indexOf(value);
            if (index > -1) {
                selectedCarriers.splice(index, 1);
            }
        }

        this.setState(prevState => {
            return {
                outputs: {
                    ...prevState.outputs,
                    underwritingGuidelines: {
                        ...prevState.outputs.underwritingGuidelines,
                        carriers: selectedCarriers,
                    },
                },
            };
        });
    };

    _renderOutputItems = () => {
        const { outputOptionInputs } = this.state;
        const selectedOutputOptions = [];

        for (const option of optionOutputInputItemsMedical) {
            if (outputOptionInputs[option.value]) {
                selectedOutputOptions.push(option);
            }
        }

        for (const option of optionOutputInputItemsDental) {
            if (outputOptionInputs[option.value]) {
                selectedOutputOptions.push(option);
            }
        }

        for (const option of optionOutputInputItemsVision) {
            if (outputOptionInputs[option.value]) {
                selectedOutputOptions.push(option);
            }
        }

        for (const option of optionOutputInputItemsLife) {
            if (outputOptionInputs[option.value]) {
                selectedOutputOptions.push(option);
            }
        }

        return (
            <React.Fragment>
                {selectedOutputOptions.map(this._renderOutputItem)}
                {selectedOutputOptions.length > 0 && selectedOutputOptions.length < (optionOutputInputItemsMedical.length + optionOutputInputItemsDental.length + optionOutputInputItemsVision.length + optionOutputInputItemsLife.length) &&
                    <div className={css(styles.addAnotherOutputContainer)}>
                        {strings.addAnotherOutputText}
                    </div>
                }
                {selectedOutputOptions.length === 0 &&
                    <div className={css(styles.chooseOptionsContainer)}>
                        {strings.chooseOptionsText}
                    </div>
                }
            </React.Fragment>
        );
    };

    _renderCompanyProfile = () => {
        const { quote } = this.props;
        return (
            <React.Fragment>
                <div className={css(styles.coverPageRow)}>
                    <div className={css(styles.coverPageColumn, styles.coverPageLabel)}>Group:</div>
                    <div className={css(styles.coverPageColumn)}>{quote.groupName}</div>
                </div>
                <div className={css(styles.coverPageRow)}>
                    <div className={css(styles.coverPageColumn, styles.coverPageLabel)}>Location:</div>
                    <div className={css(styles.coverPageColumn)}>{quote.city}, {quote.state} {quote.zip}</div>
                </div>
            </React.Fragment>
        );
    };

    _renderCoverPage = () => {
        const { quote } = this.props;
        return (
            <React.Fragment>
                <div className={css(styles.coverPageRow)}>
                    <div className={css(styles.coverPageColumn, styles.coverPageLabel)}>Broker:</div>
                    <div className={css(styles.coverPageColumn)}>{quote.brokerName}</div>
                </div>
                <div className={css(styles.coverPageRow)}>
                    <div className={css(styles.coverPageColumn, styles.coverPageLabel)}>Effective Date:</div>
                    <div className={css(styles.coverPageColumn)}>{quote.effectiveDateString}</div>
                </div>
                <div className={css(styles.coverPageRow)}>
                    <div className={css(styles.coverPageColumn, styles.coverPageLabel)}>Group:</div>
                    <div className={css(styles.coverPageColumn)}>{quote.groupName}</div>
                </div>
                <div className={css(styles.optionHeader, styles.spacer)}>
                    Select a Cover Page Image:
                </div>
                <div className={css(styles.coverPageRow)}>
                    <div className={css(styles.coverPageColumn)}>
                        <input
                            type="radio"
                            value="1"
                            name={outputInputNames.coverPageImage}
                            checked={this.state.outputs[outputInputNames.coverPageImage] === "1"}
                            onChange={this._radioChanged}
                            className={css(styles.radioCover)}
                        />
                        <img src={coverPage1} alt="Cover Page 1" />
                    </div>
                    <div className={css(styles.coverPageColumn)}>
                        <input
                            type="radio"
                            value="2"
                            name={outputInputNames.coverPageImage}
                            checked={this.state.outputs[outputInputNames.coverPageImage] === "2"}
                            onChange={this._radioChanged}
                            className={css(styles.radioCover)}
                        />
                        <img src={coverPage2} alt="Cover Page 2" />
                    </div>
                    <div className={css(styles.coverPageColumn)}>
                        <input
                            type="radio"
                            value="3"
                            name={outputInputNames.coverPageImage}
                            checked={this.state.outputs[outputInputNames.coverPageImage] === "3"}
                            onChange={this._radioChanged}
                            className={css(styles.radioCover)}
                        />
                        <img src={coverPage3} alt="Cover Page 3" />
                    </div>
                    <div className={css(styles.coverPageColumn)}>
                        <input
                            type="radio"
                            value="4"
                            name={outputInputNames.coverPageImage}
                            checked={this.state.outputs[outputInputNames.coverPageImage] === "4"}
                            onChange={this._radioChanged}
                            className={css(styles.radioCover)}
                        />
                        <img src={coverPage4} alt="Cover Page 4" />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderCensus = () => {
        const { census } = this.props.quote;
        const employeeCount = census.filter(c => c.relationship === "EE").length;
        const dependentCount = census.length - employeeCount;

        return (
            <React.Fragment>
                <div className={css(styles.optionField)}>
                    Employees: {employeeCount}
                </div>
                <div className={css(styles.optionField)}>
                    Dependents: {dependentCount}
                </div>
            </React.Fragment>
        );
    };

    _renderCensusRates = () => {
        const { censusRates } = this.state.outputs;

        return (
            <React.Fragment>
                <div className={css(styles.optionHeader)}>
                    Sort By:
                </div>
                <Toggle
                    aStyles={styles.toggle}
                    defaultChecked={censusRates.sortByCarrier}
                    onChange={this._censusRateSortChanged}
                    checkedLabel="Carrier & Plan type"
                    uncheckedLabel="Premium"
                />
                <div className={css(styles.optionHeader, styles.spacer)}>
                    Include:
                </div>
                <label className={css(styles.checkLabel)}>
                    <Checkbox
                        aStyles={styles.check}
                        checked={censusRates[outputInputNames.censusRates.ageRated]}
                        name={outputInputNames.censusRates.ageRated}
                        onChange={this._censusRateCheckChanged}
                    />
                    <span className={css(styles.labelText)}>Age Rates</span>
                </label>
                <label className={css(styles.checkLabel)}>
                    <Checkbox
                        aStyles={styles.check}
                        checked={censusRates[outputInputNames.censusRates.compositeRated]}
                        name={outputInputNames.censusRates.compositeRated}
                        onChange={this._censusRateCheckChanged}
                    />
                    <span className={css(styles.labelText)}>Composite Rates</span>
                </label>
            </React.Fragment>
        );
    };

    _renderCurrentAndRenewal = () => {
        const { quote } = this.props;
        const { currentMedicalPlans, renewalMedicalPlans } = quote;

        return (
            <React.Fragment>
                <div className={css(styles.optionField)}>
                    Current plans: {currentMedicalPlans.length}
                </div>
                <div className={css(styles.optionField)}>
                    Renewal plans: {renewalMedicalPlans.length}
                </div>
                <div className={css(styles.optionHeader, styles.spacer)}>
                    Include:
                </div>
                <label className={css(styles.checkLabel)}>
                    <Checkbox
                        aStyles={styles.check}
                        checked={this.state.outputs.currentAndRenewal[outputInputNames.currentAndRenewal.ageRated]}
                        name={outputInputNames.currentAndRenewal.ageRated}
                        onChange={this._currentAndRenewalCheckChanged}
                    />
                    <span className={css(styles.labelText)}>Age Rated</span>
                </label>
                <label className={css(styles.checkLabel)}>
                    <Checkbox
                        aStyles={styles.check}
                        checked={this.state.outputs.currentAndRenewal[outputInputNames.currentAndRenewal.ageRatedWithCensus]}
                        name={outputInputNames.currentAndRenewal.ageRatedWithCensus}
                        onChange={this._currentAndRenewalCheckChanged}
                    />
                    <span className={css(styles.labelText)}>Age Rated (with Census)</span>
                </label>
                <label className={css(styles.checkLabel)}>
                    <Checkbox
                        aStyles={styles.check}
                        checked={this.state.outputs.currentAndRenewal[outputInputNames.currentAndRenewal.compositeRated]}
                        name={outputInputNames.currentAndRenewal.compositeRated}
                        onChange={this._currentAndRenewalCheckChanged}
                    />
                    <span className={css(styles.labelText)}>Composite Rated</span>
                </label>
            </React.Fragment>
        );
    };

    _renderCostComparisons = () => {
        const { costComparisons } = this.state.outputs;

        return (
            <React.Fragment>
                <div className={css(styles.optionHeader)}>
                    Sort By:
                </div>
                <Toggle
                    aStyles={styles.toggle}
                    defaultChecked={costComparisons.sortByCarrierAndPlanType}
                    onChange={this._costComparisonSortChanged}
                    checkedLabel="Carrier & Plan type"
                    uncheckedLabel="Premium"
                />
                <div className={css(styles.optionHeader, styles.spacer)}>
                    Include:
                </div>
                <div className={css(styles.checkboxContainer)}>
                    <label className={css(styles.checkLabel)}>
                        <Checkbox
                            aStyles={styles.check}
                            checked={costComparisons[outputInputNames.costComparisons.currentAndRenewalPlans]}
                            name={outputInputNames.costComparisons.currentAndRenewalPlans}
                            onChange={e => this._costComparisonsCheckChanged(e, "medical")}
                        />
                        <span className={css(styles.labelText)}>Current & Renewal Plans</span>
                    </label>
                    <label className={css(styles.checkLabel)}>
                        <Checkbox
                            aStyles={styles.check}
                            checked={costComparisons[outputInputNames.costComparisons.ageRated]}
                            name={outputInputNames.costComparisons.ageRated}
                            onChange={e => this._costComparisonsCheckChanged(e, "medical")}
                        />
                        <span className={css(styles.labelText)}>Age Rated</span>
                    </label>
                    <label className={css(styles.checkLabel)}>
                        <Checkbox
                            aStyles={styles.check}
                            checked={costComparisons[outputInputNames.costComparisons.compositeRated]}
                            name={outputInputNames.costComparisons.compositeRated}
                            onChange={e => this._costComparisonsCheckChanged(e, "medical")}
                        />
                        <span className={css(styles.labelText)}>Composite Rated</span>
                    </label>
                </div>
            </React.Fragment>
        );
    };

    _renderCostComparisonsD = () => {
        const { costComparisonsD } = this.state.outputs;

        return (
            <React.Fragment>
                <div className={css(styles.optionHeader, styles.spacer)}>
                    Include:
                </div>
                <div className={css(styles.checkboxContainer)}>
                    <label className={css(styles.checkLabel)}>
                        <Checkbox
                            aStyles={styles.check}
                            checked={costComparisonsD[outputInputNames.costComparisonsD.currentAndRenewalPlans]}
                            name={outputInputNames.costComparisonsD.currentAndRenewalPlans}
                            onChange={e => this._costComparisonsCheckChanged(e, "dental")}
                        />
                        <span className={css(styles.labelText)}>Current & Renewal Plans</span>
                    </label>
                </div>
            </React.Fragment>
        );
    };

    _renderCostComparisonsV = () => {
        const { costComparisonsV } = this.state.outputs;

        return (
            <React.Fragment>
                <div className={css(styles.optionHeader, styles.spacer)}>
                    Include:
                </div>
                <div className={css(styles.checkboxContainer)}>
                    <label className={css(styles.checkLabel)}>
                        <Checkbox
                            aStyles={styles.check}
                            checked={costComparisonsV[outputInputNames.costComparisonsV.currentAndRenewalPlans]}
                            name={outputInputNames.costComparisonsV.currentAndRenewalPlans}
                            onChange={e => this._costComparisonsCheckChanged(e, "vision")}
                        />
                        <span className={css(styles.labelText)}>Current & Renewal Plans</span>
                    </label>
                </div>
            </React.Fragment>
        );
    };

    _renderCostComparisonsL = () => {
        const { costComparisonsL } = this.state.outputs;

        return (
            <React.Fragment>
                <label className={css(styles.checkLabel)}>
                    <span className={css(styles.labelInfo)}>* No Additional Selections</span>
                </label>
            </React.Fragment>
        );

        /*return (
            <React.Fragment>
                <div className={css(styles.optionHeader, styles.spacer)}>
                    Include:
                </div>
                <div className={css(styles.checkboxContainer)}>
                    <label className={css(styles.checkLabel)}>
                        <Checkbox
                            aStyles={styles.check}
                            checked={costComparisonsL[outputInputNames.costComparisonsL.currentAndRenewalPlans]}
                            name={outputInputNames.costComparisonsL.currentAndRenewalPlans}
                            onChange={e => this._costComparisonsCheckChanged(e, "life")}
                        />
                        <span className={css(styles.labelText)}>Current & Renewal Plans</span>
                    </label>
                </div>
            </React.Fragment>
        );*/
    };

    _spreadsheetCheckChanged = e => {
        const { checked, name, type, value } = e.target;
        const val = type === "checkbox" ? checked : value;

        const selectedSpreadsheets = this.state.outputs.spreadsheets[name];

        if (val) {
            selectedSpreadsheets.push(value);
        } else {
            const index = selectedSpreadsheets.indexOf(value);
            if (index > -1) {
                selectedSpreadsheets.splice(index, 1);
            }
        }

        this.setState(prevState => {
            return {
                outputs: {
                    ...prevState.outputs,
                    spreadsheets: {
                        ...prevState.outputs.spreadsheets,
                        [name]: selectedSpreadsheets,
                    },
                },
            };
        });
    };

    _renderSavedSpreadsheetsCheckList = (spreadsheets, name) => {
        const isSinglePlan = name === outputInputNames.spreadsheets.singlePlan;
        const isMultiPlan = name === outputInputNames.spreadsheets.multiPlan;
        const isStackedMultiPlan = name === outputInputNames.spreadsheets.stackedMultiPlan;
        return (
            <React.Fragment>
                <div className={css(styles.optionHeader, isSinglePlan ? null : styles.spacer)}>
                    {isSinglePlan ? "Single plan" : isMultiPlan ? "Multi-Option" : isStackedMultiPlan ? "Stacked Multi-Option (one per proposal)" : ""}
                </div>
                <div className={css(styles.checkboxContainer)}>
                    {spreadsheets.map((item, index) => (
                        <label className={css(styles.checkLabel)} key={index}>
                            <Checkbox
                                aStyles={styles.check}
                                checked={this.state.outputs.spreadsheets[name].includes(item.name)}
                                name={name}
                                onChange={this._spreadsheetCheckChanged}
                                value={item.name}
                            />
                            <span className={css(styles.labelText)}>{item.name}</span>
                        </label>
                    ))}
                </div>
            </React.Fragment>
        );
    };

    _renderSpreadsheets = () => {
        const { singlePlan, multiPlan, stackedMultiPlan } = this.props.spreadsheets;
        return (
            <React.Fragment>
                {singlePlan ? this._renderSavedSpreadsheetsCheckList(singlePlan, outputInputNames.spreadsheets.singlePlan) : null}
                {multiPlan ? this._renderSavedSpreadsheetsCheckList(multiPlan, outputInputNames.spreadsheets.multiPlan) : null}
                {stackedMultiPlan ? this._renderSavedSpreadsheetsCheckList(stackedMultiPlan, outputInputNames.spreadsheets.stackedMultiPlan) : null}
            </React.Fragment>
        );
    };

    _dynamicSpreadsheetsCheckChanged = e => {
        const { checked, name, type, value } = e.target;
        const val = type === "checkbox" ? checked : value;
        //check
        const { currentMedicalPlans, renewalMedicalPlans } = this.props.quote;
        //composite OR age
        if ((name === "singlePlan" || name === "singlePlanAge") && !currentMedicalPlans && !renewalMedicalPlans) {
            toast.warn("You must select Current & Renewal Plans for this output.", { toastId: 1 });
            return;
        }
        //update
        this.setState(prevState => {
            return {
                outputs: {
                    ...prevState.outputs,
                    dynamicSpreadsheets: {
                        ...prevState.outputs.dynamicSpreadsheets,
                        [name]: val,
                    },
                },
            };
        });
    };

    _renderDynamicSpreadsheets = () => {
        const { dynamicSpreadsheets } = this.state.outputs;

        return (
            <React.Fragment>
                <div className={css(styles.smallText)}>
                    Side-by-Side Dynamic Spreadsheet options only work with Excel outputs (not PDF)
                </div>
                <div className={css(styles.optionHeader)}>
                    Include:
                </div>
                <Toggle
                    aStyles={styles.toggle}
                    defaultChecked={dynamicSpreadsheets.currentAndRenewalPlans}
                    onChange={this._dynamicSpreadsheetsCRChanged}
                    checkedLabel="With Current & Renewal"
                    uncheckedLabel="Without Current & Renewal"
                />
                <div className={css(styles.smallText)}>
                    * Current and Renewal plans MUST have member/tier rates entered
                </div>
                <div className={css(styles.checkboxContainer)}>
                    <label className={css(styles.checkLabel)}>
                        <Checkbox
                            aStyles={styles.check}
                            checked={dynamicSpreadsheets[outputInputNames.dynamicSpreadsheets.singlePlanAge]}
                            name={outputInputNames.dynamicSpreadsheets.singlePlanAge}
                            onChange={this._dynamicSpreadsheetsCheckChanged}
                        />
                        <span className={css(styles.labelText)}>Single Option (Age)</span>
                    </label>
                </div>
                <div className={css(styles.checkboxContainer)}>
                    <label className={css(styles.checkLabel)}>
                        <Checkbox
                            aStyles={styles.check}
                            checked={dynamicSpreadsheets[outputInputNames.dynamicSpreadsheets.singlePlan]}
                            name={outputInputNames.dynamicSpreadsheets.singlePlan}
                            onChange={this._dynamicSpreadsheetsCheckChanged}
                        />
                        <span className={css(styles.labelText)}>Single Option (Composite)</span>
                    </label>
                </div>
                <div className={css(styles.checkboxContainer)}>
                    <label className={css(styles.checkLabel)}>
                        <Checkbox
                            aStyles={styles.check}
                            checked={dynamicSpreadsheets[outputInputNames.dynamicSpreadsheets.dualOption]}
                            name={outputInputNames.dynamicSpreadsheets.dualOption}
                            onChange={this._dynamicSpreadsheetsCheckChanged}
                        />
                        <span className={css(styles.labelText)}>Dual Option (Composite)</span>
                    </label>
                </div>
                <div className={css(styles.checkboxContainer)}>
                    <label className={css(styles.checkLabel)}>
                        <Checkbox
                            aStyles={styles.check}
                            checked={dynamicSpreadsheets[outputInputNames.dynamicSpreadsheets.tripleOption]}
                            name={outputInputNames.dynamicSpreadsheets.tripleOption}
                            onChange={this._dynamicSpreadsheetsCheckChanged}
                        />
                        <span className={css(styles.labelText)}>Triple Option (Composite)</span>
                    </label>
                </div>
                <div className={css(styles.checkboxContainer)}>
                    <label className={css(styles.checkLabel)}>
                        <Checkbox
                            aStyles={styles.check}
                            checked={dynamicSpreadsheets[outputInputNames.dynamicSpreadsheets.quadrupleOption]}
                            name={outputInputNames.dynamicSpreadsheets.quadrupleOption}
                            onChange={this._dynamicSpreadsheetsCheckChanged}
                        />
                        <span className={css(styles.labelText)}>Quadruple Option (Composite)</span>
                    </label>
                </div>
                <div className={css(styles.checkboxContainer)}>
                    <label className={css(styles.checkLabel)}>
                        <Checkbox
                            aStyles={styles.check}
                            checked={dynamicSpreadsheets[outputInputNames.dynamicSpreadsheets.multiPlan]}
                            name={outputInputNames.dynamicSpreadsheets.multiPlan}
                            onChange={this._dynamicSpreadsheetsCheckChanged}
                        />
                        <span className={css(styles.labelText)}>Multi Option Summary Worksheet (Composite)</span>
                    </label>
                </div>
            </React.Fragment>
        );
    };

    _renderEmployeeWorksheet = () => {
        const { selectedPlans } = this.props;

        return (
            <React.Fragment>
                <div className={css(styles.checkboxContainerLeft)}>
                    <label className={css(styles.checkLabel)}>
                        <Checkbox
                            aStyles={styles.check}
                            checked={this.state.outputs.employeeWorksheet[outputInputNames.employeeWorksheet.ageRated]}
                            name={outputInputNames.employeeWorksheet.ageRated}
                            onChange={this._employeeWorksheetCheckChanged}
                        />
                        <span className={css(styles.labelText)}>Age Rates</span>
                    </label>
                    <label className={css(styles.checkLabel)}>
                        <Checkbox
                            aStyles={styles.check}
                            checked={this.state.outputs.employeeWorksheet[outputInputNames.employeeWorksheet.compositeRated]}
                            name={outputInputNames.employeeWorksheet.compositeRated}
                            onChange={this._employeeWorksheetCheckChanged}
                        />
                        <span className={css(styles.labelText)}>Composite Rates</span>
                    </label>
                </div>
                <div className={css(styles.setBasePlan)}>
                    <span>{strings.baseMedicalPlan}</span>
                    <Dropdown
                        aStyles={styles.baseRateDropdown}
                        options={selectedPlans.medical.map(item => ({
                            value: item.id.toString(),
                            label: item.displayName,
                        }))}
                        placeholder={strings.selectBasePlan}
                        value={this.state.inputs[inputNames.selectedBaseMedicalPlanId]}
                        onChange={this._quoteProposalBaseMedicalPlanSelected}
                        hideLabel={true}
                        size={inputSizes.small}
                    />
                </div>
            </React.Fragment>
        );
    };

    _renderEmployeeWorksheetD = () => {
        return (
            <React.Fragment>
                <label className={css(styles.checkLabel)}>
                    <span className={css(styles.labelInfo)}>* No Additional Selections</span>
                </label>
            </React.Fragment>
        );
    };

    _renderEmployeeWorksheetV = () => {
        return (
            <React.Fragment>
                <label className={css(styles.checkLabel)}>
                    <span className={css(styles.labelInfo)}>* No Additional Selections</span>
                </label>
            </React.Fragment>
        );
    };

    _renderTableRates = () => {
        const { tableRates } = this.state.outputs;

        return (
            <React.Fragment>
                <div className={css(styles.optionHeader)}>
                    Sort By:
                </div>
                <Toggle
                    aStyles={styles.toggle}
                    defaultChecked={tableRates.sortByCarrier}
                    onChange={this._tableRateSortChanged}
                    checkedLabel="Carrier & Plan type"
                    uncheckedLabel="Premium"
                />
                <div className={css(styles.optionHeader, styles.spacer)}>
                    Include:
                </div>
                <label className={css(styles.checkLabel)}>
                    <Checkbox
                        aStyles={styles.check}
                        checked={tableRates[outputInputNames.tableRates.ageRates]}
                        name={outputInputNames.tableRates.ageRates}
                        onChange={this._tableRateCheckChanged}
                    />
                    <span className={css(styles.labelText)}>Age Rates</span>
                    {/*<a className={css(styles.downloadTemplateIcon)} href="/files/census_import.xlsx">
                        <DownloadIcon className={css(styles.downloadIcon)} />
                    </a>*/}
                </label>
                <label className={css(styles.checkLabel)}>
                    <Checkbox
                        aStyles={styles.check}
                        checked={tableRates[outputInputNames.tableRates.ageRatesVertical]}
                        name={outputInputNames.tableRates.ageRatesVertical}
                        onChange={this._tableRateCheckChanged}
                    />
                    <span className={css(styles.labelText)}>Age Rates (Vertical)</span>
                </label>
                <label className={css(styles.checkLabel)}>
                    <Checkbox
                        aStyles={styles.check}
                        checked={tableRates[outputInputNames.tableRates.compositeRates]}
                        name={outputInputNames.tableRates.compositeRates}
                        onChange={this._tableRateCheckChanged}
                    />
                    <span className={css(styles.labelText)}>Composite Rates</span>
                </label>
            </React.Fragment>
        );
    };

    _renderTableRatesD = () => {
        return (
            <React.Fragment>
                <label className={css(styles.checkLabel)}>
                    <span className={css(styles.labelInfo)}>* No Additional Selections</span>
                </label>
            </React.Fragment>
        );
    };

    _renderTableRatesV = () => {
        return (
            <React.Fragment>
                <label className={css(styles.checkLabel)}>
                    <span className={css(styles.labelInfo)}>* No Additional Selections</span>
                </label>
            </React.Fragment>
        );
    };

    _renderUnderwritingGuidelines = () => {
        return (
            <div className={css(styles.carrierContainer)}>
                {carriers.map((item, index) => (
                    <div className={css(styles.carrierItem)} key={index}>
                        <label className={css(styles.checkLabel)} key={index}>
                            <Checkbox
                                aStyles={styles.check}
                                checked={this.state.outputs.underwritingGuidelines.carriers.includes(item.id)}
                                onChange={this._underwritingGuidelinesCheckChanged}
                                value={item.id}
                            />
                            <span className={css(styles.labelText)}>{item.name}</span>
                        </label>
                    </div>
                ))}
            </div>
        );
    };

    _renderOutputItem = optionItem => {
        //pre-pend LOB
        let label = optionItem.label;
        if (optionItem.value.endsWith("D")) {
            label = "Dental " + label;
        } else if (optionItem.value.endsWith("V")) {
            label = "Vision " + label;
        } else if (optionItem.value.endsWith("L")) {
            label = "Life " + label;
        }

        return (
            <div className={css(styles.outputItem)} key={optionItem.value}>
                <div className={css(styles.optionItemLabel)}>
                    {label}
                </div>
                <div className={css(styles.optionItemContent)}>
                    {this[`_render${util.capitalize(optionItem.value)}`]()}
                </div>
            </div>
        );
    };

    _radioChanged = e => {
        const { name, value } = e.target;
        this.setState(prevState => {
            return {
                outputs: {
                    ...prevState.outputs,
                    [name]: value,
                },
            };
        });
    };

    render() {
        const { quote, selectedPlans, me } = this.props;
        const { quoteProposals, inputs, allowNewProposals } = this.state;
        const { currentMedicalPlans, renewalMedicalPlans, currentDentalPlans, renewalDentalPlans, currentVisionPlans, renewalVisionPlans, currentLifePlans, renewalLifePlans } = quote;

        //Get plan ids
        let medicalPlanIds = selectedPlans.medical.map(p => p.id);
        let dentalPlanIds = selectedPlans.dental.map(p => p.id);
        let visionPlanIds = selectedPlans.vision.map(p => p.id);
        let lifePlanIds = selectedPlans.life.map(p => p.id);

        //Load plans from the previous proposal?
        const selectedProposalId = parseInt(inputs[inputNames.selectedProposalId]);
        if (selectedProposalId > 0 && quoteProposals) {
            const selectedProposal = quoteProposals.find(p => p.id === selectedProposalId);
            medicalPlanIds = selectedProposal.medicalPlanIds;
            dentalPlanIds = selectedProposal.dentalPlanIds;
            visionPlanIds = selectedProposal.visionPlanIds;
            lifePlanIds = selectedProposal.lifePlanIds;
        }
        if (!medicalPlanIds) { medicalPlanIds = []; }
        if (!dentalPlanIds) { dentalPlanIds = []; }
        if (!visionPlanIds) { visionPlanIds = []; }
        if (!lifePlanIds) { lifePlanIds = []; }

        //census election counts
        const census = quote.census;
        const medCensusCount = census ? census.filter(x => x.relationship === "EE" && x.medicalCoverageId < 5).length : 0;
        const denCensusCount = census ? census.filter(x => x.relationship === "EE" && x.dentalCoverageId < 5).length : 0;
        const visCensusCount = census ? census.filter(x => x.relationship === "EE" && x.visionCoverageId < 5).length : 0;
        const lifCensusCount = census ? census.filter(x => x.relationship === "EE" && x.lifeCoverageId < 5).length : 0;

        return (
            <div className={css(styles.container)}>
                <ToastContainer position="top-center" autoClose={2500} />
                <Modal
                    show={this.state.showSaveModal}
                    header={strings.saveProposalModalHeader}
                >
                    <div className={css(styles.modalContent)}>
                        <div className={css(styles.modalInstructions)}>
                            {strings.proposalInstructions}
                        </div>
                        <TextInput
                            name={inputNames.name}
                            onChange={this._handleTextChange}
                            placeholder={strings.proposalNamePlaceholder}
                            required={true}
                            validationMessage={this.state.errors[inputNames.name]}
                            value={this.state.inputs[inputNames.name]}
                            size={inputSizes.small}
                            hideLabel={true}
                        />
                        {false &&
                            <div className={css(styles.modalInstructions)}>
                                {strings.proposalEmailLabel}
                            </div>
                        }
                    </div>
                    <div className={css(styles.fileTypeContainer)}>
                        <div className={css(styles.fileType)}>
                            Choose file type:
                        </div>
                        <div className={css(styles.radioContainer)}>
                            <label className={css(styles.radioLabel)}>
                                PDF
                                <input
                                    type="radio"
                                    value="pdf"
                                    name={outputInputNames.fileType}
                                    checked={this.state.outputs[outputInputNames.fileType] === "pdf"}
                                    onChange={this._radioChanged}
                                    className={css(styles.radio)}
                                />
                            </label>
                            <label className={css(styles.radioLabel)}>
                                Excel
                                <input
                                    type="radio"
                                    value="xlsx"
                                    name={outputInputNames.fileType}
                                    checked={this.state.outputs[outputInputNames.fileType] === "xlsx"}
                                    onChange={this._radioChanged}
                                    className={css(styles.radio)}
                                />
                            </label>
                        </div>
                    </div>
                    <div className={css(styles.modalButtonContainer)}>
                        <ActionButton
                            aStyles={styles.modalButton}
                            label={strings.cancelButtonText}
                            invertColor={true}
                            type="button"
                            onClick={() => this.setState({ showSaveModal: false })}
                        />
                        <ActionButton
                            aStyles={styles.modalButton}
                            label={strings.saveButtonText}
                            onClick={this._saveProposal}
                            type="button"
                            isLoading={this.state.isSaving}
                        />
                    </div>
                </Modal>
                <div className={css(styles.headerActionsContainer)}>
                    <div className={css(styles.clearContainer)}>
                        {this.state.inputs[inputNames.selectedProposalId] &&
                            <ActionButton
                                aStyles={styles.modalButton}
                                label={strings.clearButtonText}
                                invertColor={true}
                                type="button"
                                onClick={this._clearInputs}
                            />
                        }
                    </div>
                    <div className={css(styles.planMessageContainer)}>
                        {(currentMedicalPlans.length > 0 || renewalMedicalPlans.length > 0 || medicalPlanIds.length > 0) &&
                            <span>
                                {currentMedicalPlans.length} Current, {renewalMedicalPlans.length} Renewal, {medicalPlanIds.length} New Medical plan(s) <span className={css(styles.planMessageWarning)}>{medicalPlanIds.length > 1000 ? "* First 1000 included in outputs" : ""}</span><br />
                            </span>
                        }
                        {(currentDentalPlans.length > 0 || renewalDentalPlans.length > 0 || dentalPlanIds.length > 0) &&
                            <span>
                                {currentDentalPlans.length} Current, {renewalDentalPlans.length} Renewal, {dentalPlanIds.length} New Dental plan(s) <span className={css(styles.planMessageWarning)}>{dentalPlanIds.length > 1000 ? "* First 1000 included in outputs" : ""}</span><br />
                            </span>
                        }
                        {(currentVisionPlans.length > 0 || renewalVisionPlans.length > 0 || visionPlanIds.length > 0) &&
                            <span>
                                {currentVisionPlans.length} Current, {renewalVisionPlans.length} Renewal, {visionPlanIds.length} New Vision plan(s) <span className={css(styles.planMessageWarning)}>{visionPlanIds.length > 1000 ? "* First 1000 included in outputs" : ""}</span><br />
                            </span>
                        }
                        {(currentLifePlans.length > 0 || renewalLifePlans.length > 0 || lifePlanIds.length > 0) &&
                            <span>
                                {currentLifePlans.length} Current, {renewalLifePlans.length} Renewal, {lifePlanIds.length} New Life plan(s)<br />
                            </span>
                        }
                    </div>
                    <div className={css(styles.proposalContainer)}>
                        <Dropdown
                            aStyles={styles.proposalDropdown}
                            options={this.state.quoteProposals.map(item => ({
                                value: item.id.toString(),
                                label: item.name,
                            }))}
                            placeholder={strings.loadProposalPlaceholder}
                            value={this.state.inputs[inputNames.selectedProposalId]}
                            onChange={this._quoteProposalSelected}
                            hideLabel={true}
                            size={inputSizes.small}
                        />
                        {(me.isSysAdmin || allowNewProposals) &&
                            <ActionButton
                                aStyles={styles.saveProposalButton}
                                label={strings.saveProposaButtonText}
                                onClick={this._saveProposal}
                                type="button"
                                isLoading={this.state.isSaving}
                            />}
                    </div>
                </div>
                <div className={css(styles.outputContainer)}>
                    <div className={css(styles.outputOptionContainer)}>
                        <div className={css(styles.outputOptionHeader)}>
                            {strings.outputOptionsHeader}
                        </div>
                        <Tabs>
                            <div label="Medical" disabled={medCensusCount === 0 && currentMedicalPlans.length === 0 && renewalMedicalPlans.length === 0 && medicalPlanIds.length === 0}>
                                {optionOutputInputItemsMedical.filter(x => x.value !== "coverPage" && x.value !== "companyProfile" && x.value !== "census").map((item, index) => (
                                    <div className={css(styles.outputOptionItem)} key={index}>
                                        <label className={css(styles.largeCheckLabel)}>
                                            <Checkbox
                                                aStyles={styles.largeCheck}
                                                checked={this.state.outputOptionInputs[item.value]}
                                                name={item.value}
                                                onChange={this._outputOptionCheckChanged}
                                                disabled={medCensusCount === 0 && currentMedicalPlans.length === 0 && renewalMedicalPlans.length === 0 && medicalPlanIds.length === 0}
                                                size={inputSizes.large}
                                            />
                                            <div className={css(styles.largeLabelText)}>{item.label}</div>
                                        </label>
                                    </div>
                                ))}
                            </div>
                            <div label="Dental" disabled={denCensusCount === 0 && currentDentalPlans.length === 0 && renewalDentalPlans.length === 0 && dentalPlanIds.length === 0}>
                                {optionOutputInputItemsDental.map((item, index) => (
                                    <div className={css(styles.outputOptionItem)} key={index}>
                                        <label className={css(styles.largeCheckLabel)}>
                                            <Checkbox
                                                aStyles={styles.largeCheck}
                                                checked={this.state.outputOptionInputs[item.value]}
                                                name={item.value}
                                                onChange={this._outputOptionCheckChanged}
                                                disabled={denCensusCount === 0 && currentDentalPlans.length === 0 && renewalDentalPlans.length === 0 && dentalPlanIds.length === 0}
                                                size={inputSizes.large}
                                            />
                                            <div className={css(styles.largeLabelText)}>{item.label}</div>
                                        </label>
                                    </div>
                                ))}
                            </div>
                            <div label="Vision" disabled={visCensusCount === 0 && currentVisionPlans.length === 0 && renewalVisionPlans.length === 0 && visionPlanIds.length === 0}>
                                {optionOutputInputItemsVision.map((item, index) => (
                                    <div className={css(styles.outputOptionItem)} key={index}>
                                        <label className={css(styles.largeCheckLabel)}>
                                            <Checkbox
                                                aStyles={styles.largeCheck}
                                                checked={this.state.outputOptionInputs[item.value]}
                                                name={item.value}
                                                onChange={this._outputOptionCheckChanged}
                                                disabled={visCensusCount === 0 && currentVisionPlans.length === 0 && renewalVisionPlans.length === 0 && visionPlanIds.length === 0}
                                                size={inputSizes.large}
                                            />
                                            <div className={css(styles.largeLabelText)}>{item.label}</div>
                                        </label>
                                    </div>
                                ))}
                            </div>
                            <div label="Life" disabled={lifCensusCount === 0 && currentLifePlans.length === 0 && renewalLifePlans.length === 0 && lifePlanIds.length === 0}>
                                {optionOutputInputItemsLife.map((item, index) => (
                                    <div className={css(styles.outputOptionItem)} key={index}>
                                        <label className={css(styles.largeCheckLabel)}>
                                            <Checkbox
                                                aStyles={styles.largeCheck}
                                                checked={this.state.outputOptionInputs[item.value]}
                                                name={item.value}
                                                onChange={this._outputOptionCheckChanged}
                                                disabled={lifCensusCount === 0 && currentLifePlans.length === 0 && renewalLifePlans.length === 0 && lifePlanIds.length === 0}
                                                size={inputSizes.large}
                                            />
                                            <div className={css(styles.largeLabelText)}>{item.label}</div>
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </Tabs>
                    </div>
                    <div className={css(styles.outputsContainer)}>
                        {this._renderOutputItems()}
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = state => ({
    quote: state.proposal.quote,
    spreadsheets: state.proposal.spreadsheets,
    selectedPlans: state.proposal.selectedPlans,
    me: state.session.me,
});

export default connect(
    mapDispatchToProps,
    null,
)(Output);