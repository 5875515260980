import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import Tabs from "../Tabs";
import SinglePlanSpreadsheet from "./SinglePlanSpreadsheet";
import MultiPlanSpreadsheet from "./MultiPlanSpreadsheet";
import StackedMultiOptionSpreadsheet from "./StackedMultiOptionSpreadsheet";
import * as quoteProposalApi from "../../lib/api/quoteProposal";
import * as proposalActions from "../../actions/proposalActions";

const styles = StyleSheet.create({
    container: {

    },
    buttonContainer: {
        height: 80,
        margin: "24px auto 0px auto",
        textAlign: "center",
        marginTop: 60,
    },
    saveButton: {
        minWidth: 200,
    },
    backButon: {
        marginRight: 32,
        width: 180,
    },
});

const strings = {
    singlePlanTabText: "Single Plan",
    mutliPlanTabText: "Multiple Plan",
    stackedMutliOptionTabText: "Stacked Multi-Option (Age Only)",
};

export class ManualSpreadsheet extends Component {
    static propTypes = {
        quoteId: PropTypes.number.isRequired,
        setSpreadsheetType: PropTypes.func.isRequired,
        tabChanged: PropTypes.func,
        spreadsheets: PropTypes.object.isRequired,
        setSpreadsheets: PropTypes.func.isRequired,
        setSubStep: PropTypes.func,
    };

    state = {
        inputs: {},
        selectedSpreadsheetId: null,
        currentTab: "Single Plan",
    };

    _backClicked = () => {
        this.props.setSpreadsheetType(null);
    };

    _saveClicked = () => {

    };

    _saveSinglePlan = spreadsheet => {
        const { spreadsheets } = this.props;
        const singlePlanSpreadsheets = spreadsheets.singlePlan;

        const index = singlePlanSpreadsheets ? singlePlanSpreadsheets.findIndex(s => s.name === spreadsheet.name) : -1;

        if (index === -1) {
            singlePlanSpreadsheets.push(spreadsheet);
        } else {
            singlePlanSpreadsheets[index] = spreadsheet;
        }

        spreadsheets.singlePlan = singlePlanSpreadsheets;

        quoteProposalApi.saveSpreadsheet(this.props.quoteId, spreadsheets)
            .then(result => {
                this.props.setSpreadsheets(JSON.parse(result.spreadsheets));
            });
    };

    _saveMultiPlanOption = spreadsheet => {
        const { spreadsheets } = this.props;
        const multiPlanSpreadsheets = spreadsheets.multiPlan;

        const index = multiPlanSpreadsheets ? multiPlanSpreadsheets.findIndex(s => s.name === spreadsheet.name) : -1;

        if (index === -1) {
            multiPlanSpreadsheets.push(spreadsheet);
        } else {
            multiPlanSpreadsheets[index] = spreadsheet;
        }

        spreadsheets.multiPlan = multiPlanSpreadsheets;

        quoteProposalApi.saveSpreadsheet(this.props.quoteId, spreadsheets)
            .then(result => {
                this.props.setSpreadsheets(JSON.parse(result.spreadsheets));
            });
    };

    _saveStackedMultiPlanOption = spreadsheet => {
        const { spreadsheets } = this.props;
        const stackedMultiPlanSpreadsheets = spreadsheets.stackedMultiPlan;

        const index = stackedMultiPlanSpreadsheets ? stackedMultiPlanSpreadsheets.findIndex(s => s.name === spreadsheet.name) : -1;

        if (index === -1) {
            stackedMultiPlanSpreadsheets.push(spreadsheet);
        } else {
            stackedMultiPlanSpreadsheets[index] = spreadsheet;
        }

        spreadsheets.stackedMultiPlan = stackedMultiPlanSpreadsheets;

        quoteProposalApi.saveSpreadsheet(this.props.quoteId, spreadsheets)
            .then(result => {
                this.props.setSpreadsheets(JSON.parse(result.spreadsheets));
            });
    };

    _tabChanged = currentTab => {
        this.setState({ currentTab });
        this.props.tabChanged(currentTab);
    };

    _setSubStep = step => {
        this.props.setSubStep(step);
    }

    render() {
        return (
            <div className={css(styles.container)}>
                <Tabs tabChanged={this._tabChanged}>
                    <div label={strings.singlePlanTabText}>
                        <SinglePlanSpreadsheet
                            onSave={this._saveSinglePlan}
                            onCancel={this._backClicked}
                        />
                    </div>
                    <div label={strings.mutliPlanTabText}>
                        <MultiPlanSpreadsheet
                            onSave={this._saveMultiPlanOption}
                            onCancel={this._backClicked}
                            setSubStep={this._setSubStep}
                        />
                    </div>
                    <div label={strings.stackedMutliOptionTabText}>
                        <StackedMultiOptionSpreadsheet
                            onSave={this._saveStackedMultiPlanOption}
                            onCancel={this._backClicked}
                            setSubStep={this._setSubStep}
                        />
                    </div>
                </Tabs>
            </div>
        );
    }
}

const mapDispatchToProps = state => ({
    spreadsheetType: state.proposal.spreadsheetType,
    spreadsheets: state.proposal.spreadsheets,
});

export default connect(
    mapDispatchToProps, {
    setSpreadsheetType: proposalActions.setSpreadsheetType,
    setSpreadsheets: proposalActions.setSpreadsheets,
},
)(ManualSpreadsheet);
